import { Delete, RowingOutlined } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tooltip from "@mui/material/Tooltip";
import { DeleteJobs } from "layouts/common/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getJobJobsService, getFilteredService } from "shared/services";
import { formatDate } from "util/Helper";
import MDBox from "./../../components/MDBox/index";
import AddJobDialog from "./components/AddJobDialog";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@mui/material/Button";
import moment from "moment";
import Papa from "papaparse";
import {
  deleteJobJobs,
  getJobJobsList,
  getPermanentJobList,
  getFilteredList,
} from "./Redux/actionCreator";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterCandidateTypes,
  filterBranch,
  filterSpecialization,
  updateStartDate,
  updateEndDate,
} from "./Redux/reducer";
import {
  setProfessionType,
  setSpecialisationsType,
} from "layouts/events/Redux/actionCreator";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import { css } from "glamor";
import { hospitalBranch } from "./../../services/hospitalServices";
const Jobs = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { job_Data } = useSelector((state) => state.job);
  const softwares = job_Data?.job_Data_info;

  const { job_Filtered_Data } = useSelector((state) => state.job);
  const filteredJobData = job_Filtered_Data?.job_Filtered_Data_info;
  // console.log("Filtered", filteredJobData)

  const totalCount = job_Data?.total;
  const currentPage = useSelector((state) => state.job.currentPage);
  const pageSize = useSelector((state) => state.job.pageSize);
  const searchKey = useSelector((state) => state.job.searchKey);
  const filterCandidateType = useSelector((state) => state.job.candidateType);
  const filterBranchData = useSelector((state) => state.job.branch);
  const filterSpecializationData = useSelector(
    (state) => state.job.specialization
  );
  const startDate = useSelector((state) => state.job.startDate);
  const endDate = useSelector((state) => state.job.endDate);

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");

  const [candidateValue, setCandidateValue] = useState("");
  const [branchValue, setBranchValue] = useState("");
  const [specializationValue, setSpecializationValue] = useState("");
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");

  const professionOptions = [];
  const branchOptions = [];
  const specializationOptions = [];

  const { Profession_Type_Data, Specialization_Type_Data } = useSelector(
    (state) => state.events
  );
  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  const professionData = Profession_Type_Data?.professionInfo;
  const branchData = hospital_branch_Data?.branchInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getJobJobsList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          candidateType: filterCandidateType,
          branch: filterBranchData,
          specialization: filterSpecializationData,
          // filterAll: filterAll,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterCandidateType,
    filterBranchData,
    filterSpecializationData,
    // filterAll,
    startDate,
    endDate,
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getFilteredList({
  //         searchKey: searchKey,
  //         candidateType: filterCandidateType,
  //         branch: filterBranchData,
  //         specialization: filterSpecializationData,
  //         startDate: startDate,
  //         endDate: endDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   searchKey,
  //   filterCandidateType,
  //   filterBranchData,
  //   filterSpecializationData,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const hasAnyFilter =
  //       searchKey.trim() !== "" ||
  //       filterCandidateType?.trim() !== "" ||
  //       filterBranchData?.trim() !== "" ||
  //       filterSpecializationData?.trim() !== "" ||
  //       startDate.trim() !== "" ||
  //       endDate.trim() !== "";

  //     try {
  //       if (hasAnyFilter) {
  //         await dispatch(
  //           getFilteredList({
  //             searchKey: searchKey,
  //             candidateType: filterCandidateType,
  //             branch: filterBranchData,
  //             specialization: filterSpecializationData,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       } else {
  //         // If nothing is specified, you might want to handle this case
  //         await dispatch(
  //           getJobJobsList({
  //             currentPage: currentPage,
  //             pageSize: pageSize,
  //             searchKey: searchKey,
  //             candidateType: filterCandidateType,
  //             branch: filterBranchData,
  //             specialization: filterSpecializationData,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKey,
  //   filterCandidateType,
  //   filterBranchData,
  //   filterSpecializationData,
  //   startDate,
  //   endDate,
  // ]);

  useEffect(() => {
    dispatch(setProfessionType());
    dispatch(HospitalBranchList([true]));
    dispatch(setSpecialisationsType());
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(getFilteredList({searchKey: searchKey, filterAll: filterAll, startDate: startDate, endDate: endDate }));
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, searchKey, filterAll, startDate, endDate]);

  // useEffect(() => {
  //   // dispatch(getJobJobsList());
  //   dispatch(getJobJobsList({ currentPage: currentPage, pageSize: pageSize }));
  // }, [dispatch,currentPage,pageSize]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  //Delete JOBS
  const handleDelete = (ID) => {
    dispatch(deleteJobJobs(ID));
  };

  //VALUE GETTER FUNCTIONS
  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getCode(params) {
    return `${params?.row?.code || "NA"}`;
  }

  function getBranchName(params) {
    return `${params?.row?.branch?.name || "NA"}`;
  }

  function getCandidateTypeName(params) {
    return `${params?.row?.candidateType?.name || "NA"}`;
  }

  function getDepartmentName(params) {
    return `${params?.row?.department?.name || "NA"}`;
  }

  function getOrganizationsName(params) {
    return `${params?.row?.organization?.name || "NA"}`;
  }

  function getQualificationsName(params) {
    return `${params?.row?.qualification?.name || "NA"}`;
  }

  function getnumExperienceName(params) {
    return `${params?.row?.numExperience?.name || "NA"}`;
  }
  function getMaxRate(params) {
    return `${params?.row?.maxRate || "NA"}`;
  }
  function getMinRate(params) {
    return `${params?.row?.minRate || "NA"}`;
  }

  function getCityName(params) {
    return `${params?.row?.branch?.address?.city || "NA"}`;
  }

  function getSpecializationName(params) {
    return `${params?.row?.specialization?.name || "NA"}`;
  }

  function getSpecializationName(params) {
    return `${params?.row?.specialization?.name || "NA"}`;
  }

  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }

  function getCompanyByName(params) {
    return `${params?.row?.company?.name || "NA"}`;
  }

  function consequtiveTimeStartDateTime(params) {
    var formattedDate;
    if (params?.row?.consequtiveTime?.start?.datetime) {
      //   var rowName = formatDate(params?.row?.consequtiveTime?.start?.datetime);
      //   var dateNOW = new Date(rowName);
      //   return dateNOW;
      // } else return "NA";
      var rowName = new Date(params?.row?.consequtiveTime?.start?.datetime);
      var day = rowName.getDate();
      var month = rowName.getMonth() + 1;
      var year = rowName.getFullYear();
      var hours = rowName.getHours().toString().padStart(2, "0");
      var minutes = rowName.getMinutes().toString().padStart(2, "0");
      var seconds = rowName.getSeconds().toString().padStart(2, "0");

      formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;
      return `${formattedDate}`;
    } else {
      formattedDate = "NA";
      return `${formattedDate}`;
    }
  }

  function consequtiveTimeEndDateTime(params) {
    var formattedDate;
    if (params?.row?.consequtiveTime?.end) {
      //   var rowName = formatDate(params?.row?.consequtiveTime?.end?.datetime);
      //   var dateNOW = new Date(rowName);
      //   return dateNOW;
      // } else return "NA";
      var rowName = new Date(params?.row?.consequtiveTime?.end?.datetime);
      if (rowName == "Invalid Date") {
        formattedDate = "NA";
        return `${formattedDate}`;
      }
      var day = rowName.getDate();
      var month = rowName.getMonth() + 1;
      var year = rowName.getFullYear();
      var hours = rowName.getHours().toString().padStart(2, "0");
      var minutes = rowName.getMinutes().toString().padStart(2, "0");
      var seconds = rowName.getSeconds().toString().padStart(2, "0");

      formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;
      return `${formattedDate}`;
    } else {
      formattedDate = "NA";
      return `${formattedDate}`;
    }
  }

  function nonConsequtiveTimeStartDateTime(params) {
    var formattedDate;
    if (params?.row?.nonConsequtiveTime?.start?.datetime) {
      //   var rowName = formatDate(
      //     params?.row?.nonConsequtiveTime?.start?.datetime
      //   );
      //   var dateNOW = new Date(rowName);
      //   return dateNOW;
      // } else return "NA";

      var rowName = new Date(params?.row?.nonConsequtiveTime?.start?.datetime);
      if (rowName === "Invalid Date") {
        formattedDate = "NA";
        return `${formattedDate}`;
      }
      var day = rowName.getDate();
      var month = rowName.getMonth() + 1;
      var year = rowName.getFullYear();
      var hours = rowName.getHours().toString().padStart(2, "0");
      var minutes = rowName.getMinutes().toString().padStart(2, "0");
      var seconds = rowName.getSeconds().toString().padStart(2, "0");

      formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;
      // T${hours}:${minutes}:${seconds}`;
      return `${formattedDate}`;
    } else {
      formattedDate = "NA";
      return `${formattedDate}`;
    }
  }

  function nonConsequtiveTimeEndDateTime(params) {
    var formattedDate;
    if (params?.row?.nonConsequtiveTime?.end) {
      //   var rowName = formatDate(params?.row?.nonConsequtiveTime?.end?.datetime);
      //   var dateNOW = new Date(rowName);
      //   return dateNOW;
      // } else return "NA";
      var rowName = new Date(params?.row?.nonConsequtiveTime?.end?.datetime);
      // console.log("182-->", rowName);
      if (rowName == "Invalid Date") {
        formattedDate = "NA";
        return `${formattedDate}`;
      }
      var day = rowName.getDate();
      var month = rowName.getMonth() + 1;
      var year = rowName.getFullYear();
      var hours = rowName.getHours().toString().padStart(2, "0");
      var minutes = rowName.getMinutes().toString().padStart(2, "0");
      var seconds = rowName.getSeconds().toString().padStart(2, "0");

      formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;
      // T${hours}:${minutes}:${seconds}`;
      return `${formattedDate}`;
    } else {
      formattedDate = "NA";
      return `${formattedDate}`;
    }
  }

  // function getCreatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }
  function getCreatedAT(params) {
    var a;
    var rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();

    // var formattedDate = `${year}/${
    //   month < 10 ? "0" + month : month
    // }/${day < 10 ? "0" + day : day}`;

    // return formattedDate;
    // const rowName = params?.row?.createdAt;
    // const a = moment(rowName).format("MM/DD/YYYY");

    // return a;
  }

  // function getUpdatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }

  function getUpdatedAT(params) {
    var a;
    var rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.updatedAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
    // var formattedDate;
    // // var formattedDate = `${day < 10 ? "0" + day : day}/${
    // //   month < 10 ? "0" + month : month
    // // }/${year}`;
    // if(formattedDate){
    //   formattedDate = `${year}/${
    //     month < 10 ? "0" + month : month
    //   }/${day < 10 ? "0" + day : day}`;
    //   return `${formattedDate}`
    // }else{
    //   formattedDate = "NA";
    //   return `${formattedDate}`;
    // }
  }

  function getShiftLogs(params) {
    const shiftLogsArray = params?.row?.shiftLogs;
    const approvedStatus = [];
    if (shiftLogsArray) {
      shiftLogsArray.map((obj) => {
        var dateNOW = new Date(obj?.date?.datetime);
        var day = dateNOW.getDate();
        var month = dateNOW.getMonth() + 1;
        var year = dateNOW.getFullYear();

        var formattedDate = `${day < 10 ? "0" + day : day}/${
          month < 10 ? "0" + month : month
        }/${year}`;
        approvedStatus.push(`${formattedDate}-${obj?.approved} `);
      });
    }

    return approvedStatus;
  }

  //JOBS COLUMNS
  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowId = cellValues?.row?._id;
        return rowId;
      },

      valueGetter: getID,
      flex: 1.2,
    },
    {
      field: "code",
      headerName: `Job Id`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.code;
        const rowId = cellValues?.row?._id;
        return <Link to={`code/${rowCode}/${rowId}`}>{rowCode}</Link>;
      },
      valueGetter: getCode,
      flex: 1.2,
    },
    {
      field: "branch",
      headerName: `Hospital Branch`,
      renderCell: (cellValues) => {
        const rowBranch = cellValues?.row?.branch;
        return (
          <Link underline="hover" to={`/hospital/branch/${rowBranch?._id}`}>
            {rowBranch?.name ? rowBranch?.name : "N.A"}
          </Link>
        );
      },
      valueGetter: getBranchName,
      flex: 1.5,
    },

    {
      field: "candidateType",
      headerName: `Candidate Type`,
      renderCell: (cellValues) => {
        const rowCandidateType = cellValues?.row?.candidateType;

        return (
          <Link
            underline="hover"
            to={`/common/profession/${rowCandidateType?._id}`}
          >
            {" "}
            {rowCandidateType?.name ? rowCandidateType.name : "N.A"}
          </Link>
        );
      },
      flex: 1.5,
      valueGetter: getCandidateTypeName,
    },
    {
      field: "department",
      headerName: `Department`,
      renderCell: (cellValues) => {
        const rowDepartment = cellValues?.row?.department;
        return (
          <Link to={`/hospital/department/${rowDepartment?._id}`}>
            {" "}
            {rowDepartment?.name ? rowDepartment.name : "N.A"}
          </Link>
        );
      },
      flex: 1.5,
      valueGetter: getDepartmentName,
    },
    {
      field: "state",
      headerName: `State`,

      flex: 1.2,
    },
    {
      field: "numberOfJobs",
      headerName: `Number Of Jobs`,
      flex: 1.5,
    },
    {
      field: "numberOfDays",
      headerName: `Number Of Days`,
      flex: 1.5,
    },
    {
      field: "shiftLogs",
      headerName: `Shift Logs`,
      renderCell: (cellValues) => {
        const shiftLogsArray = cellValues?.row?.shiftLogs;
        const approvedStatus = [];
        if (shiftLogsArray) {
          shiftLogsArray.map((obj) => {
            var dateNOW = new Date(obj?.date?.datetime);
            var day = dateNOW.getDate();
            var month = dateNOW.getMonth() + 1;
            var year = dateNOW.getFullYear();

            var formattedDate = `${day < 10 ? "0" + day : day}/${
              month < 10 ? "0" + month : month
            }/${year}`;
            approvedStatus.push(`${formattedDate}-${obj?.approved} `);
          });
        }

        return approvedStatus;
      },
      flex: 1,
      valueGetter: getShiftLogs,
    },

    {
      field: "isDeleted",
      headerName: `isDeleted`,
      type: "boolean",
      renderCell: (cellValues) => {
        var checkboxValue = cellValues?.row?.isDeleted
          ? cellValues?.row?.isDeleted
          : false;
        return (
          <Checkbox
            {...label}
            checked={checkboxValue}
            //checked={cellValues?.row?.isDeleted}
            //defaultChecked={cellValues?.row?.isDeleted}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "isFood",
      headerName: `isFood`,
      type: "boolean",
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isFood}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "isAccommodation",
      headerName: `isAccommodation`,
      type: "boolean",
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isAccommodation}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "isTransport",
      headerName: `isTransport`,
      type: "boolean",
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isTransport}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "organizations",
      headerName: `Company`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.organizations?.name;
        return roleName;
      },
      flex: 1,
      valueGetter: getOrganizationsName,
    },
    {
      field: "company",
      headerName: `Corporate`,
      valueGetter: getCompanyByName,
      flex: 1.5,
    },
    {
      field: "cancelReason",
      headerName: `cancelReason`,
      flex: 1.5,
    },
    {
      field: "qualification",
      headerName: `Qualification`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.qualification?.name;
        return roleName;
      },
      flex: 1,
      valueGetter: getQualificationsName,
    },

    {
      field: "getMaxRate",
      headerName: `MaxRate`,
      description: "MaxRate",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.MaxRate;
        return roleName;
      },
      flex: 1.2,
      valueGetter: getMaxRate,
    },
    {
      field: "getMinRate",
      headerName: `MinRate`,
      description: "MinRate",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.MinRate;
        return roleName;
      },
      flex: 1.2,
      valueGetter: getMinRate,
    },
    {
      field: "getCityName",
      headerName: `Hospital City`,
      description: ` Hospital City Name`,
      renderCell: (cellValues) => {
        const CityName = cellValues?.row?.getCityName;
        return CityName;
      },
      flex: 1.2,
      valueGetter: getCityName,
    },

    {
      field: "numExperience",
      headerName: `Num Experience`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.numExperience?.name;
        return roleName;
      },
      flex: 1,
      valueGetter: getnumExperienceName,
    },
    {
      field: "specialization",
      headerName: `Specialization`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.specialization?.name;
        return roleName;
      },
      flex: 1,
      valueGetter: getSpecializationName,
    },
    {
      field: "timingsType",
      headerName: `Timings Type`,
      flex: 1.5,
    },
    {
      field: "isJobFinished",
      headerName: `Shift Status Flag`,
      flex: 1.5,
    },
    // {
    //   field: "createdAt",
    //   type: "date",
    //   headerName: `Created At`,
    //   flex: 1.5,

    //   valueGetter: getCreatedAT,
    // },
    {
      field: "additionalSkills",
      headerName: `Additional Skill`,
      renderCell: (cellValues) => {
        console.log("cellValues", cellValues)
        const additionalSkills = cellValues?.row?.additionalSkill;
        if (additionalSkills && additionalSkills.length > 0) {
          const skillNames = additionalSkills
            .map((skill) => skill.name)
            .join(", ");
          // console.log("skillNames", skillNames)
          return (
            <Tooltip title={skillNames} arrow>
              <div>{skillNames}</div>
            </Tooltip>
          );
          // return skillNames;
        } else {
          return "NA";
        }
      },
      type: "date",

      // valueGetter: getCreatedAT,
    },

    // {
    //   field: "shiftlogStatus",
    //   headerName: `Shiftlog Status`,
    //   renderCell: (cellValues) => {
    //     console.log("cellValues", cellValues);

    //     const shiftogs = cellValues?.row?.shiftLogs?.map((shiftog, index) => {
    //       const userId = shiftog.doneBy?._id;
    //       return (
    //         <div key={index}>
    //           {index + 1}.{" "}
    //           <Link to={`/users/name/${userId}`}>
    //             {shiftog.doneBy?.name?.full}
    //           </Link>
    //           , {shiftog.approved}
    //         </div>
    //       );
    //     });

    //     return (
    //       <div
    //         style={{
    //           maxHeight: "50px",
    //           overflowY: "scroll",
    //           overflowX: "hidden",
    //           maxWidth: "300px",
    //           padding: "10px",
    //         }}
    //       >
    //         <Tooltip title={shiftogs} arrow>
    //           <div>{shiftogs}</div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    //   // valueGetter: getCode,
    //   flex: 3,
    // },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.createdAt);
        // var dayyy = new Date(rowName);
        // var a, b, c;
        // a = dayyy.getDate();
        // b = dayyy.getMonth() + 1;
        // c = dayyy.getFullYear();
        // var dateNow;

        // // dateNow = a + "/" + b + "/" + c;
        // // dateNow = `${c}/${a < 10 ? "0" + a : a}/${b < 10 ? "0" + b : b}`;
        // // return dateNow;
        // if(dateNow){
        //   dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
        //   return `${dateNow}`;
        // }else{
        //   dateNow = "NA";
        //   return `${dateNow}`;
        // }
      },
      type: "date",

      valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    //   flex: 1.5,
    // },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        // console.log("1-->", cellValues)
        var a;
        var rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.updatedAt);
        // var dayyy = new Date(rowName);
        // var a, b, c;
        // a = dayyy.getDate();
        // b = dayyy.getMonth() + 1;
        // c = dayyy.getFullYear();
        // var dateNow;

        // // dateNow = a + "/" + b + "/" + c;
        // dateNow = `${c}/${a < 10 ? "0" + a : a}/${b < 10 ? "0" + b : b}`;
        // return dateNow;
      },
      type: "date",

      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.code}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                {" "}
                <Delete fontSize="small" onClick={handlePopupOpen} />{" "}
              </IconButton>
            )}
          />{" "}
        </>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: "consequtiveTimeStartDatetime",
      headerName: "Consequtive Time Start Datetime",
      renderCell: (cellValues) => {
        var formattedDate;
        if (cellValues?.row?.consequtiveTime?.start?.datetime) {
          //   var rowName = formatDate(params?.row?.consequtiveTime?.start?.datetime);
          //   var dateNOW = new Date(rowName);
          //   return dateNOW;
          // } else return "NA";
          var rowName = new Date(
            cellValues?.row?.consequtiveTime?.start?.datetime
          );
          var day = rowName.getDate();
          var month = rowName.getMonth() + 1;
          var year = rowName.getFullYear();
          var hours = rowName.getHours().toString().padStart(2, "0");
          var minutes = rowName.getMinutes().toString().padStart(2, "0");
          var seconds = rowName.getSeconds().toString().padStart(2, "0");

          formattedDate = ` ${year}/${month < 10 ? "0" + month : month}/${
            day < 10 ? "0" + day : day
          }T${hours}:${minutes}:${seconds}`;
          return `${formattedDate}`;
        } else {
          formattedDate = "NA";
          return `${formattedDate}`;
        }
      },

      type: "date",
      valueGetter: consequtiveTimeStartDateTime,
      flex: 1.2,
    },

    {
      field: "consequtiveTimeEndDatetime",
      headerName: "Consequtive Time End Datetime",
      renderCell: (cellValues) => {
        var formattedDate;
        if (cellValues?.row?.consequtiveTime?.end) {
          var rowName = new Date(
            cellValues?.row?.consequtiveTime?.end?.datetime
          );
          if (rowName == "Invalid Date") {
            formattedDate = "NA";
            return `${formattedDate}`;
          }
          var day = rowName.getDate();
          var month = rowName.getMonth() + 1;
          var year = rowName.getFullYear();
          var hours = rowName.getHours().toString().padStart(2, "0");
          var minutes = rowName.getMinutes().toString().padStart(2, "0");
          var seconds = rowName.getSeconds().toString().padStart(2, "0");

          formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
            day < 10 ? "0" + day : day
          }T${hours}:${minutes}:${seconds}`;
          return `${formattedDate}`;
        } else {
          formattedDate = "NA";
          return `${formattedDate}`;
        }
      },
      type: "date",
      valueGetter: consequtiveTimeEndDateTime,
      flex: 1.2,
    },

    {
      field: "nonConsequtiveTimeEndDatetime",
      headerName: "Non Consequtive Time End Datetime",
      renderCell: (cellValues) => {
        var formattedDate;
        if (cellValues?.row?.nonConsequtiveTime?.end) {
          var rowName = new Date(
            cellValues?.row?.nonConsequtiveTime?.end?.datetime
          );
          if (rowName == "Invalid Date") {
            formattedDate = "NA";
            return `${formattedDate}`;
          }
          var day = rowName.getDate();
          var month = rowName.getMonth() + 1;
          var year = rowName.getFullYear();
          var hours = rowName.getHours().toString().padStart(2, "0");
          var minutes = rowName.getMinutes().toString().padStart(2, "0");
          var seconds = rowName.getSeconds().toString().padStart(2, "0");

          formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
            day < 10 ? "0" + day : day
          }T${hours}:${minutes}:${seconds}`;
          return `${formattedDate}`;
        } else {
          formattedDate = "NA";
          return `${formattedDate}`;
        }
      },
      type: "date",
      // renderCell: (cellValues) => {
      //   if (cellValues?.row?.nonConsequtiveTime?.end) {
      //     var a = cellValues?.row?.nonConsequtiveTime?.end;
      //     var b =
      //       a?.date +
      //       "/" +
      //       a?.month +
      //       "/" +
      //       a?.year +
      //       ", " +
      //       a?.hour +
      //       ":" +
      //       a?.min +
      //       ":" +
      //       a?.sec;
      //     return b;
      //   } else {
      //     return "NA";
      //   }
      //   // const rowCode = `${cellValues?.row?.nonConsequtiveTime?.end?.date}+"/"+${cellValues?.row?.nonConsequtiveTime?.end?.month}+"/"+${cellValues?.row?.nonConsequtiveTime?.end?.year}+", "+${cellValues?.row?.nonConsequtiveTime?.end?.hour}+":"+${cellValues?.row?.nonConsequtiveTimee?.end?.min}+":"+${cellValues?.row?.nonConsequtiveTime?.end?.sec}`;
      //   // return rowCode;
      // },
      valueGetter: nonConsequtiveTimeEndDateTime,
      flex: 1.2,
    },

    {
      field: "nonConsequtiveTimeStartDatetime",
      headerName: "Non Consequtive Time Start Datetime",
      renderCell: (cellValues) => {
        var formattedDate;
        if (cellValues?.row?.nonConsequtiveTime?.start?.datetime) {
          var rowName = new Date(
            cellValues?.row?.nonConsequtiveTime?.start?.datetime
          );
          if (rowName === "Invalid Date") {
            formattedDate = "NA";
            return `${formattedDate}`;
          }
          var day = rowName.getDate();
          var month = rowName.getMonth() + 1;
          var year = rowName.getFullYear();
          var hours = rowName.getHours().toString().padStart(2, "0");
          var minutes = rowName.getMinutes().toString().padStart(2, "0");
          var seconds = rowName.getSeconds().toString().padStart(2, "0");

          formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
            day < 10 ? "0" + day : day
          }T${hours}:${minutes}:${seconds}`;
          return `${formattedDate}`;
        } else {
          formattedDate = "NA";
          return `${formattedDate}`;
        }
      },
      type: "date",
      // renderCell: (cellValues) => {
      //   if (cellValues?.row?.nonConsequtiveTime?.start) {
      //     var a = cellValues?.row?.nonConsequtiveTime?.start;
      //     var b =
      //       a?.date +
      //       "/" +
      //       a?.month +
      //       "/" +
      //       a?.year +
      //       ", " +
      //       a?.hour +
      //       ":" +
      //       a?.min +
      //       ":" +
      //       a?.sec;
      //     return b;
      //   }
      //   // const rowCode = `${cellValues?.row?.nonConsequtiveTime?.start?.date}+"/"+${cellValues?.row?.nonConsequtiveTime?.start?.month}+"/"+${cellValues?.row?.nonConsequtiveTime?.start?.year}+", "+${cellValues?.row?.nonConsequtiveTime?.start?.hour}+":"+${cellValues?.row?.nonConsequtiveTime?.start?.min}+":"+${cellValues?.row?.nonConsequtiveTime?.start?.sec}`;
      //   // return rowCode;
      //   else return "NA";
      // },
      valueGetter: nonConsequtiveTimeStartDateTime,
      flex: 1.2,
    },

    { field: "jobDescription", headerName: `Job Desc`, flex: 1.2 },
    { field: "cost", headerName: `Cost`, flex: 1.2 },

    {
      field: "contactDetailEmail",
      headerName: `Contact Detail Email`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.email;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailPhone",
      headerName: `Contact Detail Phone`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.phone;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressAddress",
      headerName: `Contact Detail Address Address`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.address;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressCity",
      headerName: `Contact Detail Address City`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.city;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressState",
      headerName: `Contact Detail Address State`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.state;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressPin",
      headerName: `Contact Detail Address Pin`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.pin;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressCountry",
      headerName: `Contact Detail Address Country`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.cuntry;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailAddressCoords",
      headerName: `Contact Detail Address Coords`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.address?.coords[0];
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateYear",
      headerName: `Contact Detail Date Year`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.year;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateMonth",
      headerName: `Contact Detail Date Month`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.month;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateDate",
      headerName: `Contact Detail Date Date`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.date;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateHour",
      headerName: `Contact Detail Date Hour`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.hour;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateMin",
      headerName: `Contact Detail Date min`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.min;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateSec",
      headerName: `Contact Detail Date Sec`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.sec;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateMillis",
      headerName: `Contact Detail Date Millis`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.millis;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateTz",
      headerName: `Contact Detail Date Tz`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.tz;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailDateTs",
      headerName: `Contact Detail Date Ts`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.date?.ts;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactDays",
      headerName: `Contact Detail Contact Days`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactDays[0];
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartYear",
      headerName: `Contact Detail Contact Time Start Year`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.year;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartMonth",
      headerName: `Contact Detail Contact Time Start Month`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.month;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartDate",
      headerName: `Contact Detail Contact Time Start Date`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.date;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartHour",
      headerName: `Contact Detail Contact Time Start Hour`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.hour;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartMin",
      headerName: `Contact Detail Contact Time Start Min`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.min;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartSec",
      headerName: `Contact Detail Contact Time Start Sec`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.sec;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartMillis",
      headerName: `Contact Detail Contact Time Start Millis`,
      renderCell: (cellValues) => {
        const rowCode =
          cellValues?.row?.contactDetail?.contactTimeStart?.millis;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartTz",
      headerName: `Contact Detail Contact Time Start Tz`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.tz;
        return rowCode;
      },
      flex: 1.2,
    },
    {
      field: "contactDetailContactTimeStartTs",
      headerName: `Contact Detail Contact Time Start Ts`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.contactDetail?.contactTimeStart?.ts;
        return rowCode;
      },
      flex: 1.2,
    },
  ];
  const rows = [];
  // const handleCellClick = (event) => {
  //   event.originalEvent.stopPropagation();
  // };
  // const handleRowClick = (event) => {
  //   event.originalEvent.stopPropagation();
  // };

  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      const response = await getJobJobsService(checked, {
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
      });
      // const response = await getJobJobsService(checked);

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      const response = await getJobJobsService(checked, {
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
      });
      // const response = await getJobJobsService(checked);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleManualValueChange = (event) => {
    setManualValue(event.target.value);
  };

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  // const handleDownloadData = async () => {
  //   // console.log("1239>>>>>>", filteredJobData);
  //   // console.log("================================", softwares);
  //   // const filterAll = filteredJobData ? filteredJobData : softwares;
  //   // Apply your filter and search criteria
  //   // const filteredData =filteredJobData.filter(item => {
  //   //   return (
  //   //     (selectedOption === '' ) &&
  //   //     (manualValue === '' ) ||
  //   //     (searchKey === '' || item?.jobId?.includes(searchKey) || item?.branch?.includes(searchKey) || item?.city?.includes(searchKey))
  //   //   );
  //   // });
  //   //  console.log("1260---->", filteredData)

  //   const dataSource = softwares || filteredJobData;

  //   const formatDate = (dateString) => {
  //     return moment(dateString).format("YYYY/MM/DD");
  //   };

  //   const columnMapping = {
  //     code: "JobId",
  //     "branch.name": "Hospital Branch",
  //     "candidateType.name": "Candidate Type",
  //     "department.name": "Department",
  //     state: "State",
  //     numberOfJobs: " Number Of Jobs",
  //     numberOfDays: "Number Of Days",
  //     maxRate: "MaxRate",
  //     minRate: "MinRate",
  //     "branch.address.city": "Hospital City",
  //     isJobFinished: "Shift Status Flag",
  //     createdAt: "Created At",
  //     updatedAt: "Updated At",
  //   };

  //   // const filterAll = filteredData ? filteredData : softwares;

  //   const transformedData = dataSource.map((item) => {
  //     const transformedItem = {};
  //     Object.keys(columnMapping).forEach((databaseKey) => {
  //       const displayKey = columnMapping[databaseKey];
  //       if (databaseKey === "createdAt" || databaseKey === "updatedAt") {
  //         transformedItem[displayKey] = formatDate(item[databaseKey]);
  //       } else {
  //         const keys = databaseKey.split(".");
  //         let value = item;
  //         for (const key of keys) {
  //           value = value?.[key];
  //         }
  //         transformedItem[displayKey] = value;
  //       }
  //     });
  //     return transformedItem;
  //   });

  //   // Convert the filtered data to a CSV string using PapaParse
  //   const csv = Papa.unparse(transformedData);

  //   // Create a Blob object with the CSV data
  //   const blob = new Blob([csv], { type: "text/csv" });

  //   // Create a download link and trigger the download
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = "Jobizo Super Admin.csv";
  //   link.click();
  // };

  // Function to handle the download when the button is clicked

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
    try {
      setLoading(true);

      const hasAnyFilter =
        searchKey.trim() !== "" ||
        filterCandidateType?.trim() !== "" ||
        filterBranchData?.trim() !== "" ||
        filterSpecializationData?.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "";

      let csvData = "";

      if (hasAnyFilter) {
        const data = await dispatch(
          getFilteredList({
            searchKey: searchKey,
            candidateType: filterCandidateType,
            branch: filterBranchData,
            specialization: filterSpecializationData,
            startDate: startDate,
            endDate: endDate,
          })
        );
        console.log("data", data);
        csvData = data.payload.data;
      } else {
        const data = await dispatch(
          getFilteredList({
            searchKey: searchKey,
            candidateType: filterCandidateType,
            branch: filterBranchData,
            specialization: filterSpecializationData,
            startDate: startDate,
            endDate: endDate,
          })
        );
        console.log("data", data);
        csvData = data.payload.data;
      }

      if (csvData && csvData.length > 0) {
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Jobs-${formatDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (softwares && softwares.length > 0) {
        const formatDate = (dateString) =>
          moment(dateString).format("YYYY/MM/DD");

        const columnMapping = {
          code: "JobId",
          "branch.name": "Hospital Branch",
          "candidateType.name": "Candidate Type",
          "department.name": "Department",
          state: "State",
          numberOfJobs: "Number Of Jobs",
          numberOfDays: "Number Of Days",
          maxRate: "Max Rate",
          minRate: "Min Rate",
          "branch.address.city": "Hospital City",
          isJobFinished: "Shift Status Flag",
          createdAt: "Created At",
          updatedAt: "Updated At",
        };

        const transformedData = softwares.map((item) => {
          const transformedItem = {};
          Object.keys(columnMapping).forEach((databaseKey) => {
            const displayKey = columnMapping[databaseKey];
            if (databaseKey === "createdAt" || databaseKey === "updatedAt") {
              transformedItem[displayKey] = formatDate(item[databaseKey]);
            } else {
              const keys = databaseKey.split(".");
              let value = item;
              for (const key of keys) {
                value = value?.[key];
              }
              transformedItem[displayKey] = value;
            }
          });
          return transformedItem;
        });

        const csv = Papa.unparse(transformedData);
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Jobs-${formatDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleDownloadData = async () => {
  //   try {
  //     // console.log("filter-->", filteredJobData)

  //     if (filteredJobData && filteredJobData.length > 0) {
  //       const response = filteredJobData;
  //       // console.log("1405-->",response)
  //       const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       // link.download = 'Jobizo Super Admin.csv';
  //       link.download = `Jobs-${formatDate()}.csv`;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else if (softwares && softwares.length > 0) {
  //       // console.log("222>>", softwares)
  //       const formatDate = (dateString) => {
  //         return moment(dateString).format("YYYY/MM/DD");
  //       };

  //       const columnMapping = {
  //         code: "JobId",
  //         "branch.name": "Hospital Branch",
  //         "candidateType.name": "Candidate Type",
  //         "department.name": "Department",
  //         state: "State",
  //         numberOfJobs: " Number Of Jobs",
  //         numberOfDays: "Number Of Days",
  //         maxRate: "MaxRate",
  //         minRate: "MinRate",
  //         "branch.address.city": "Hospital City",
  //         isJobFinished: "Shift Status Flag",
  //         createdAt: "Created At",
  //         updatedAt: "Updated At",
  //       };

  //       const transformedData = softwares.map((item) => {
  //         const transformedItem = {};
  //         Object.keys(columnMapping).forEach((databaseKey) => {
  //           const displayKey = columnMapping[databaseKey];
  //           if (databaseKey === "createdAt" || databaseKey === "updatedAt") {
  //             transformedItem[displayKey] = formatDate(item[databaseKey]);
  //           } else {
  //             const keys = databaseKey.split(".");
  //             let value = item;
  //             for (const key of keys) {
  //               value = value?.[key];
  //             }
  //             transformedItem[displayKey] = value;
  //           }
  //         });
  //         return transformedItem;
  //       });

  //       const csv = Papa.unparse(transformedData);

  //       // Create a Blob object with the CSV data
  //       const blob = new Blob([csv], { type: "text/csv" });

  //       // Create a download link and trigger the download
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       // link.download = "Jobizo Super Admin.csv";
  //       link.download = `Jobs-${formatDate()}.csv`;
  //       link.click();
  //     } else {
  //       console.error("Failed to fetch the file");
  //     }
  //   } catch (error) {
  //     console.error("Error during file download:", error);
  //   }
  // };

  const handleCandidateTypeFilterChange = (e) => {
    const selectedCandidate = e.target.value;
    let selectedCandidateObjectID;

    professionOptions.forEach((candidate) => {
      // console.log("candidate" , candidate)
      if (candidate.value === selectedCandidate) {
        selectedCandidateObjectID = candidate.key;
      }
    });

    setCandidateValue(selectedCandidate);

    dispatch(filterCandidateTypes(selectedCandidateObjectID));
  };

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    branchOptions.forEach((branch) => {
      if (branch.value === selectedBranch) {
        selectedBranchObjectID = branch.key;
      }
    });

    setBranchValue(selectedBranch);
    dispatch(filterBranch(selectedBranchObjectID));
  };

  const handleSpecializationFilterChange = (e) => {
    const selectedSpecialization = e.target.value;
    let selectedSpecsObjectID;

    specializationOptions.forEach((specs) => {
      if (specs.value === selectedSpecialization) {
        selectedSpecsObjectID = specs.key;
      }
    });

    setSpecializationValue(selectedSpecialization);

    dispatch(filterSpecialization(selectedSpecsObjectID));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate)); // Dispatch action to update startDate in Redux store
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate)); // Dispatch action to update endDate in Redux store

    // Trigger API call when both startDate and endDate are available
    // if (startDate && endDate) {
    //   // Example: Call your API here
    //   console.log('API call with start date:', startDate, 'end date:', endDate);
    // }
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    if (sanitizedSearchKey) {
           dispatch(searchKeyword(sanitizedSearchKey));
        } else {
          dispatch(updateStartDate(inputStartDate));
          dispatch(updateEndDate(inputEndDate));
        }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <DashboardLayout>
      {" "}
      <DashboardNavbar />{" "}
      <FilterBar
        title="Create Jobs"
        title1="Locum Jobs"
        isOpen={isOpen}
        showDialog={false}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        dialogContent={<AddJobDialog handleDialogClose={handleDialogClose} />}
      />{" "}
      {/* <div> */}
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
        {/* <MenuItem value="code">Job ID</MenuItem>
        <MenuItem value="state">State</MenuItem> */}
        <MenuItem value="HospitalBranch">Hospital Branch</MenuItem>
        {/* <MenuItem value="DepartmentName">Department Name</MenuItem> */}
        <MenuItem value="CandidateType">Candidate Type</MenuItem>
        <MenuItem value="Specialization">Specialization</MenuItem>
      </Select>
      {selectedOption && (
        <>
          {selectedOption === "CandidateType" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select CandidateType
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select CandidateType"
                // value={filterAll.Role || ""}
                value={candidateValue || ""}
                onChange={handleCandidateTypeFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {professionOptions.map((candidate) => (
                  <MenuItem key={candidate.value} value={candidate.value}>
                    {candidate.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : selectedOption === "HospitalBranch" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select HospitalBranch
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select HospitalBranch"
                // value={filterAll.Role || ""}
                value={branchValue || ""}
                onChange={handleBranchFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {branchOptions.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : // ) : selectedOption === "Specialisation" ? (
          //   <FormControl
          //     fullWidth
          //     style={{ marginLeft: "20px", width: "100px", height: "44px" }}
          //   >
          //     <InputLabel
          //       id="role-select-label"
          //       style={{ textAlign: "center" }}
          //     >
          //       Select Specialization
          //     </InputLabel>
          //     <Select
          //       labelId="role-select-label"
          //       label="Select Specialization"
          //       // value={filterAll.Role || ""}
          //       value={getSpecializationValue || ""}
          //       onChange={handleSpecializationFilterChange}
          //       fullWidth
          //       // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
          //       style={{ width: "150px", height: "44px" }}
          //     >
          //       <MenuItem value="">Reset</MenuItem>
          //       {departmentTypeOption.map((spec) => (
          //         <MenuItem key={spec.value} value={spec.value}>
          //           {spec.value}
          //         </MenuItem>
          //       ))}
          //     </Select>
          //   </FormControl>
          // ) : selectedOption === "Organization" ? (
          //   <FormControl
          //     fullWidth
          //     style={{ marginLeft: "20px", width: "100px", height: "44px" }}
          //   >
          //     <InputLabel
          //       id="role-select-label"
          //       style={{ textAlign: "center" }}
          //     >
          //       Select Organization
          //     </InputLabel>
          //     <Select
          //       labelId="role-select-label"
          //       label="Select Organization"
          //       // value={filterAll.Role || ""}
          //       value={organizationsValue || ""}
          //       onChange={handleOrganizationsFilterChange}
          //       fullWidth
          //       // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
          //       style={{ width: "150px", height: "44px" }}
          //     >
          //       <MenuItem value="">Reset</MenuItem>
          //       {organizationOptions.map((orga) => (
          //         <MenuItem key={orga.value} value={orga.value}>
          //           {orga.value}
          //         </MenuItem>
          //       ))}
          //     </Select>
          //   </FormControl>
          selectedOption === "Specialization" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select Specialization
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select Specialization"
                // value={filterAll.Role || ""}
                value={specializationValue || ""}
                onChange={handleSpecializationFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {specializationOptions.map((specs) => (
                  <MenuItem key={specs.value} value={specs.value}>
                    {specs.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      )}
      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          value={inputStartDate}
          // onChange={(e) => handleStartDateChange(e.target.value)}
          onChange={(e) => setInputStartDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={inputEndDate}
          // onChange={(e) => handleEndDateChange(e.target.value)}
          onChange={(e) => setInputEndDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
      </>
      {/* </div> */}
      <Button
        onClick={handleDownloadData}
        variant="contained"
        color="primary"
        disabled={loading}
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
        }}
      >
        {loading ? "Loading..." : "Export"}
      </Button>
      <>
        <TextField
          label={`Search by Job Code`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by Job Code`}
        value={searchKey}
        onChange={(e) => dispatch(searchKeyword(e.target.value))}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      <MDBox sx={{ marginTop: "2rem" }}>
        {" "}
        <DataGrid
          density="compact"
          getRowId={(row) => row?._id}
          columns={columns}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          // loading={softwares.length > 0 ? false : true}
          loading={loading && <CircularProgress />}
          rows={showDeltedRecords ? deletedRecords : [...softwares]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                organizations: false,
                company: false,
                cancelReason: false,
                isDeleted: false,
                qualification: false,
                numExperience: false,
                specialization: false,
                timingsType: false,
                contactDetailContactDays: false,
                consequtiveTimeStartDatetime: false,
                consequtiveTimeEndDatetime: false,
                nonConsequtiveTimeStartDatetime: false,
                nonConsequtiveTimeEndDatetime: false,
                randomTime: false,
                jobDescription: false,
                numberOfJobs: true,
                numberOfDays: true,
                minRate: true,
                maxRate: true,
                CityName: true,

                cost: false,
                shift: false,
                contactDetailAddressAddress: false,
                contactDetailAddressCity: false,
                contactDetailAddressState: false,
                contactDetailAddressPin: false,
                contactDetailAddressCountry: false,
                contactDetailAddressCoords: false,
                contactDetailEmail: false,
                contactDetailPhone: false,
                contactDetailContactTimeStartYear: false,
                contactDetailContactTimeStartMonth: false,
                contactDetailContactTimeStartDate: false,
                contactDetailContactTimeStartHour: false,
                contactDetailContactTimeStartMin: false,
                contactDetailContactTimeStartSec: false,
                contactDetailContactTimeStartMillis: false,
                contactDetailContactTimeStartTz: false,
                contactDetailContactTimeStartTs: false,
                contactDetailDateYear: false,
                contactDetailDateMonth: false,
                contactDetailDateDate: false,
                contactDetailDateHour: false,
                contactDetailDateMin: false,
                contactDetailDateSec: false,
                contactDetailDateMillis: false,
                contactDetailDateTz: false,
                contactDetailDateTs: false,
                notInterested: false,
                createdAt: true,
                qualifiation: false,
                updatedAt: true,
                createdBy: false,
                updatedBy: false,
                isFood: false,
                isAccommodation: false,
                isTransport: false,
                _id: false,
                delete: false,
                shiftLogs: false,
              },
            },
          }}
          // pagination
          // pageSize={100}
          // rowsPerPageOptions={[100]}

          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          disableColumnFilter
        />
      </MDBox>
    </DashboardLayout>
  );
};
export default Jobs;
