import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { styled } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import userDetailsValidationSchema from "../../../util/validations/userDetailsValidation";
// import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import { Edit } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DynamicCoords from "examples/DynamicCoords";
import {
  setOrganizationsDropdown,
  setUsersDropdown,
} from "layouts/common/Redux/actionCreator";
import {
  setExperience,
  setProfessionType,
  setQualificationType,
  setSpecialisationsType,
} from "layouts/events/Redux/actionCreator";
import { getDepartmentsList } from "layouts/hospital/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigationType } from "react-router-dom";
import { getJobJobsByIdService } from "shared/services";
import { updateJobJobs } from "../Redux/actionCreator";
import SelectDropDownNew from "./../../../examples/DropDownNew/index";

import {
  HospitalBranchList,
  getCompanyList,
} from "layouts/hospital/Redux/actionCreator";
import moment from "moment";
import { setCityList } from "layouts/events/Redux/actionCreator";

function JobDetailsByJobCode() {
  const navigation = useNavigationType();

  const { City_List_Data } = useSelector((state) => state.events);
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }));

  const selectOptions = [
    {
      key: "PROPOSED",
      value: "PROPOSED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "APPROVED",
      value: "APPROVED",
    },
    {
      key: "ONGOING",
      value: "ONGOING",
    },
    {
      key: "VALIDATION",
      value: "VALIDATION",
    },
    {
      key: "COMPLETED",
      value: "COMPLETED",
    },
    {
      key: "CANCELLED",
      value: "CANCELLED",
    },
  ];

  const timingOptions = [
    {
      key: "CONSEQUTIVE",
      value: "CONSEQUTIVE",
    },
    {
      key: "NON_CONSEQUTIVE",
      value: "NON_CONSEQUTIVE",
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }





// Custom Tooltip with Black Background and White Text
const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    fontSize: "12px",
    borderRadius: "4px",
  },
  arrow: {
    color: "black",
  },
}));

const convertShiftlogUTCToIST = (utcDate) => {
  // console.log("utcDate", utcDate)
  if (!utcDate) {
    return "No timestamps";
  }
  const date = new Date(utcDate);
  const istOffset = 5 * 60 + 30; 
  const utcOffset = date.getTimezoneOffset();
  const istDate = new Date(date.getTime() + (istOffset + utcOffset) * 60 * 1000);
  const month = String(istDate.getMonth() + 1).padStart(2, '0');
  const day = String(istDate.getDate()).padStart(2, '0');
  const year = istDate.getFullYear();
  let hours = istDate.getHours();
  const minutes = String(istDate.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const paddedHours = String(hours).padStart(2, '0');
  return `${month}/${day}/${year} ${paddedHours}:${minutes} ${ampm}`;
};
  const columns = [
    {
      field: "date",
      headerName: `Date`,
      flex: 1,
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;

        return (
          <>
            <MDInput
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder=""
              value={moment(
                getValues(`shiftLogs[${index}].date.datetime`)
              ).format("DD/MM/YYYY")}
              disabled
              {...register(`shiftLogs[${index}].date.datetime`)}
              sx={{ width: "100%", border: "none" }}
              inputProps={{
                style: { fontSize: 11, fontWeight: "bold" },
              }}
            />
          </>
        );
      },
      // cellValues?.row?.date ? cellValues?.row?.date : "N.A",
    },
    {
      field: "doneBy",
      headerName: `Professional Name`,
      flex: 0.75,
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        const doneByName = getValues(`shiftLogs[${index}].doneBy.name.full`);
        return (
          <>
          <Tooltip title={doneByName || "N.A"} arrow>
            <MDInput
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder=""
              // disabled
              value={doneByName ? doneByName : null}
              // {...register(`shiftLogs[${index}].doneBy.name.full`)}
              sx={{ width: "100%", border: "none" }}
              inputProps={{
                style: { fontSize: 11, fontWeight: "bold" },
                // title: doneByName || "N.A",
              }}
            />
            </Tooltip>
          </>
        );
      },
      // cellValues?.row?.professionalName
      //   ? cellValues?.row?.professionalName
      //   : "N.A",
    },
    {
      field: "shiftStartTime",
      renderHeader: () => (
        <strong
          style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
        >
          {`Shift Start Time`}   
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      flex: 1,
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        const startTime = getValues(`shiftLogs[${index}].startTime.datetime`);
        const istStartTime = convertShiftlogUTCToIST(startTime);
        return (
          <>
            <Controller
              name={`shiftLogs[${index}].startTime.datetime`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Tooltip title={istStartTime || "N.A"} arrow>
                  <span>
                    <DateTimePicker
                      {...field}
                      value={istStartTime ? istStartTime : null}
                      inputProps={{
                        style: { fontSize: 11, fontWeight: "bold" },
                        
                      }}
                      {...register(`shiftLogs[${index}].startTime.datetime`)}
                      onChange={(newValue) => {
                        setValue(
                          `shiftLogs[${index}].startTime.datetime`,
                          newValue.$d.toISOString()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={onKeyDown}
                          autoComplete="off"
                        />
                      )}
                    />
                    </span>
                  </Tooltip>
                </LocalizationProvider>
              )}
            />
          </>
        );
      },
      // cellValues?.row?.shiftStartTime
      //   ? cellValues?.row?.shiftStartTime
      //   : "N.A",
    },
    {
      field: "shiftEndTime",
      renderHeader: () => (
        <strong
          style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
        >
          {`Shift End Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      flex: 1,
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        let prePopulatedValue = cellValues?.row?.endTime?.datetime;
        const endTime = getValues(`shiftLogs[${index}].endTime.datetime`);
        const istEndTime = convertShiftlogUTCToIST(endTime);
        return (
          <>
            <Controller
              name={`shiftLogs[${index}].endTime.datetime`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Tooltip title={istEndTime || "N.A"} arrow>
                  <span>
                    <DateTimePicker
                      minDate={getValues(
                        `shiftLogs[${index}].startTime.datetime`
                      )}
                      {...field}
                      value={istEndTime ? istEndTime : null}
                      inputProps={{
                        style: { fontSize: 11, fontWeight: "bold" },
                        // title: endTime || "N.A",
                      }}
                      {...register(`shiftLogs[${index}].endTime.datetime`)}
                      onChange={(newValue) => {
                        setValue(
                          `shiftLogs[${index}].endTime.datetime`,
                          newValue.$d.toISOString()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={onKeyDown}
                          autoComplete="off"
                        />
                      )}
                    />
                    </span>
                  </Tooltip>
                </LocalizationProvider>
              )}
            />
          </>
        );
      },
      // cellValues?.row?.shiftEndTime ? cellValues?.row?.shiftEndTime : "N.A",
    },
    {
      field: "checkInTime",
      renderHeader: () => (
        <strong
          style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
        >
          {`Check In Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        let prePopulatedValue = cellValues?.row?.checkinTime?.datetime;
        const checkInTime = getValues(`shiftLogs[${index}].checkinTime.datetime`)
        const istCheckInTime = convertShiftlogUTCToIST(checkInTime);
        return (
          <>
            <Controller
              name={`shiftLogs[${index}].checkinTime.datetime`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Tooltip title={istCheckInTime || "N.A"} arrow>
                  <span>
                  <DateTimePicker
                    {...field}
                    value={
                      istCheckInTime
                        ? istCheckInTime
                        : null
                    }
                    inputProps={{
                      style: { fontSize: 11, fontWeight: "bold" },
                      // title: checkInTime || "N.A",
                    }}
                    {...register(`shiftLogs[${index}].checkinTime.datetime`)}
                    onChange={(newValue) => {
                      setValue(
                        `shiftLogs[${index}].checkinTime.datetime`,
                        newValue.$d.toISOString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={onKeyDown}
                        autoComplete="off"
                      />
                    )}
                  />
                  </span>
                  </Tooltip>
                </LocalizationProvider>
              )}
            />
          </>
        );
      },

      // cellValues?.row?.checkInTime ? cellValues?.row?.checkInTime : "N.A",
      // editable: true,
      flex: 1,
    },
    {
      field: "checkOutTime",
      renderHeader: () => (
        <strong
          style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
        >
          {`Check Out Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        let prePopulatedValue = cellValues?.row?.checkinTime?.datetime;
        const checkOutTime =  getValues(`shiftLogs[${index}].checkoutTime.datetime`)
        const istCheckOutTime = convertShiftlogUTCToIST(checkOutTime);
        return (
          <>
            <Controller
              name={`shiftLogs[${index}].checkoutTime.datetime`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Tooltip title={istCheckOutTime || "N.A"} arrow>
                  <span>
                  <DateTimePicker
                    minDate={getValues(
                      `shiftLogs[${index}].checkinTime.datetime`
                    )}
                    {...field}
                    value={
                      istCheckOutTime
                        ? istCheckOutTime
                        : null
                    }
                    inputProps={{
                      style: { fontSize: 11, fontWeight: "bold" },
                      // title: checkOutTime || "N.A",
                    }}
                    {...register(`shiftLogs[${index}].checkoutTime.datetime`)}
                    onChange={(newValue) => {
                      setValue(
                        `shiftLogs[${index}].checkoutTime.datetime`,
                        newValue.$d.toISOString()
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={onKeyDown}
                        autoComplete="off"
                      />
                    )}
                  />
                  </span>
                  </Tooltip>
                </LocalizationProvider>
              )}
            />
          </>
        );
      },
      //   cellValues?.row?.checkOutTime ? cellValues?.row?.checkOutTime : "N.A",
      // editable: true,
      flex: 1,
    },
    {
      field: "approved",
      renderHeader: () => (
        <strong
          style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
        >
          {`Shift Status`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      renderCell: (cellValues) => {
        let index = cellValues?.row?.registerID;
        return (
          <>
            <MDInput
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder=""
              {...register(`shiftLogs[${index}].approved`)}
              sx={{ width: "100%", border: "none" }}
              inputProps={{
                style: { fontSize: 11, fontWeight: "bold" },
              }}
            />
          </>
        );
      },
      //   cellValues?.row?.checkOutTime ? cellValues?.row?.checkOutTime : "N.A",
      // editable: true,
      flex: 1,
    },

    {
      field: "isPremium",
      headerName: `IsPremium`,
      flex: 1,
      renderCell: (cellValues) => {
        // console.log("1-->",cellValues)
        let index = cellValues?.row?.registerID;

        return (
          <>
            <MDInput
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder=""
              {...register(`shiftLogs[${index}].isPremium`)}
              disabled
              sx={{ width: "100%", border: "none" }}
              inputProps={{
                style: { fontSize: 11, fontWeight: "bold" },
                // value: {...register(`shiftLogs[${index}].isPremium`)} ? "True" : "False"
              }}
            />
          </>
        );
      },
      // cellValues?.row?.date ? cellValues?.row?.date : "N.A",
    },

    {
      field: "costToCandidate",
      headerName: `Cost To Professional`,
      flex: 1,
      renderCell: (cellValues) => {
        // console.log("1-->",cellValues)
        let index = cellValues?.row?.registerID;

        return (
          <>
            <MDInput
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              placeholder=""
              {...register(`shiftLogs[${index}].costToCandidate`)}
              disabled
              sx={{ width: "100%", border: "none" }}
              inputProps={{
                style: { fontSize: 11, fontWeight: "bold" },
                // value: {...register(`shiftLogs[${index}].isPremium`)} ? "True" : "False"
              }}
            />
          </>
        );
      },
      // cellValues?.row?.date ? cellValues?.row?.date : "N.A",
    },
  ];

  const [responseData, setResponseData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [shiftLogRows, setShiftLogRows] = useState([]);
  const [consequtiveStartDateTime, setConsequtiveStartDateTime] =
    useState(null);

  console.log("responseData>>", responseData);

  const handleConsequtiveStartDateChange = (newValue) => {
    setConsequtiveStartDate(newValue);
  };

  const handleConsequtiveStartDateTimeNow = () => {
    setConsequtiveStartDateTime(Date().now);
  };

  // CONSECUTIVE END DATETIME

  const [consequtiveEndDateTime, setConsequtiveEndDateTime] = useState(null);

  const handleConsequtiveEndDateTimeChange = (newValue) => {
    setConsequtiveEndDateTime(newValue);
  };

  const handleConsequtiveEndDateTimeNow = () => {
    setConsequtiveEndDateTime(Date().now);
  };

  // NON CONSECUTIVE START DATETIME

  const [nonConsequtiveStartDateTime, setNonConsequtiveStartDateTime] =
    useState(null);

  const handleNonConsequtiveStartDateTimeChange = (newValue) => {
    setNonConsequtiveStartDateTime(newValue);
  };

  const handleNonConsequtiveStartDateTimeNow = () => {
    setNonConsequtiveStartDateTime(Date().now);
  };

  // NON CONSECUTIVE END DATETIME

  const [nonConsequtiveEndDateTime, setNonConsequtiveEndDateTime] =
    useState(null);

  const handleNonConsequtiveEndDateTime = (newValue) => {
    setNonConsequtiveEndDateTime(newValue);
  };

  const handleNonConsequtiveEndDateTimeNow = () => {
    setNonConsequtiveEndDateTime(Date().now);
  };

  // CONTACT TIME START

  const [contactStartDateTime, setContactStartDateTime] = useState(null);

  const handleContactStartDateTime = (newValue) => {
    setContactStartDateTime(newValue);
  };

  const handleContactStartDateTimeNow = () => {
    setContactStartDateTime(Date().now);
  };

  // CONTACT TIME END
  const [contactEndDateTime, setContactEndDateTime] = useState(null);

  const handleContactEndDateTime = (newValue) => {
    setContactEndDateTime(newValue);
  };

  const handleContactEndDateTimeNow = () => {
    setContactEndDateTime(Date().now);
  };
  const convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [day, month, date.getFullYear()].join("-");
  };
  const code = window.location.href.split("code")[1].split("/")[1];
  const ID = window.location.href.split("code")[1].split("/")[2];
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getData = async () => {
    let response = await getJobJobsByIdService(ID);

    setResponseData(response);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    let createdAT = getValues("created_at");
    if (createdAT == "Invalid date") {
      setValue("created_at", "");
    }

    if (createdAT != undefined && createdAT !== "") {
      if (!updated) {
        setValue(
          "created_at",
          new Date(createdAT).toLocaleString().split(",")[0]
        );
        setUpdated(true);
      }
    }
  }, [getValues("created_at")]);

  const [updated1, setUpdated1] = useState(false);

  useEffect(() => {
    let updatedAt = getValues("updated_at");

    if (updatedAt == "Invalid date") {
      setValue("updated_at", "");
    }
    if (updatedAt !== undefined && updatedAt !== "") {
      if (!updated)
        setValue(
          "updated_at",
          new Date(updatedAt).toLocaleString().split(",")[0]
        );
      setUpdated(true);
    }
  }, [getValues("updated_at")]);

  const [consequtiveStartDate, setConsequtiveStartDate] = useState();
  const [updated2, setUpdated2] = useState(false);
  const cityData = City_List_Data?.cityListInfo;
  console.log(cityData, "cityData");
  const cityOptions = [];

  cityData.map((ele) => {
    let obj = {
      key: ele.name,
      value: ele.name,
    };
    cityOptions.push(obj);
  });
  useEffect(() => {
    const shiftLogValues = getValues("shiftLogs");
    if (shiftLogValues !== undefined) {
      if (!updateFlag) {
        let rowsArray = [];
        shiftLogValues.map((obj, index) => {
          obj.registerID = index;
          rowsArray.push(obj);
        });

        setShiftLogRows(rowsArray);
        setUpdateFlag(true);
      }
    }
  }, [getValues("shiftLogs")]);

  const {
    fields: RandomTimeField,
    append: RandomTimeAppend,
    remove: RandomTimeRemove,
  } = useFieldArray({
    control,
    name: `randomTime`,
  });
  const {
    fields: shiftlogField,
    append: shiftlogAppend,
    remove: shiftlogRemove,
  } = useFieldArray({
    control,
    name: `Shiftlog`,
  });
  const {
    fields: coordField,
    append: coordAppend,
    remove: coordRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "Coord", // unique name for your Field Array
  });
  const { hospital_department_Data, hospital_branch_Data } = useSelector(
    (state) => state.hospital
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [value, setValue] = useState(null);
  const onSubmit = (data) => {
    let addressCoordsPayload = [];
    let branchAddressCoordsPayload = [];

    data?.contactDetail?.address?.coords.map((obj) => {
      if (typeof obj === "object") {
        addressCoordsPayload.push("");
      } else {
        addressCoordsPayload.push(obj);
      }
    });
    data?.branch?.address?.coords.map((obj) => {
      if (typeof obj === "object") {
        branchAddressCoordsPayload.push("");
      } else {
        branchAddressCoordsPayload.push(obj);
      }
    });

    let shiftLogsPayload = [];
    data?.shiftLogs?.map((obj) => {
      // console.log("obj1-->", obj)
      // if(obj.registerID >= 0){
      //   delete obj.registerID;
      // }
      obj.doneBy = obj?.doneBy?._id;
      shiftLogsPayload.push(obj);
    });

    const payload = {
      consequtiveTime: {
        start: {
          datetime: data?.consequtiveTime?.start?.datetime,
        },
        end: {
          datetime: data?.consequtiveTime?.end?.datetime,
        },
      },
      nonConsequtiveTime:
        data?.nonConsequtiveTime?.start?.datetime != ""
          ? {
              weekdays: data?.nonConsequtiveTime?.weekdays,
              start: {
                datetime: data?.nonConsequtiveTime?.start?.datetime,
              },
              end: {
                datetime: data?.nonConsequtiveTime?.end?.datetime,
              },
            }
          : {
              weekdays: data?.nonConsequtiveTime?.weekdays,
            },

      contactDetail: {
        address: {
          coords: addressCoordsPayload,
          address: data.contactDetail.address.address,
          city: data.contactDetail.address.city,
          state: data.contactDetail.address.state,
          country: data.contactDetail.address.country,
          pin: data.contactDetail.address.pin,
        },
        contactDays: selectedDays,
        email: data.contactDetail.email,
        phone: data.contactDetail.phone,
      },
      _id: ID,
      notInterested: selected,
      isDeleted: data.isDeleted,
      state: data.state,
      organization: organizationID ? organizationID : data?.organization._id,
      company: companyID ? companyID : data?.company._id,
      department: departmentID ? departmentID : data?.department?._id,
      branch: branchID ? branchID : data?.branch?._id,
      cancelReason: data?.cancelReason,
      qualification: qualificationID
        ? qualificationID
        : data?.qualification?._id,
      numExperience: experienceID ? experienceID : data?.numExperience?._id,
      specialization: specializationID
        ? specializationID
        : data?.specialization?._id,
      candidateType: candidateTypeID
        ? candidateTypeID
        : data?.candidateType?._id,
      jobDescription: data.jobDescription,
      minRate: data.minRate,
      maxRate: data.maxRate,
      numberOfJobs: data.numberofJobs,
      isFood: data.isFood,
      isAccommodation: data.isAccommodation,
      isTransport: data.isTransport,
      cost: data.cost,
      numberOfDays: data.numberOfDays,
      timingsType: data.timingsType,
      startTime: dayjs(contactStartDateTime).format("YYYY-MM-DDTHH:mm:ssZ[Z]"),
      endTime: dayjs(contactEndDateTime).format("YYYY-MM-DDTHH:mm:ssZ[Z]"),

      shiftLogs: shiftLogsPayload,

      recommendedUsers: data.recommendedUsers,
      // __v: 0,
      code: code,
    };

    dispatch(updateJobJobs({ payload: payload, id: ID })).then(() => {
      navigate("/jobDetails");
    });
  };

  const { OrganizationsData, UsersData } = useSelector((state) => state.common);
  const { hospital_company_Data } = useSelector((state) => state.hospital);
  const {
    Experience_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Profession_Type_Data,
  } = useSelector((state) => state.events);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const notInterestedInfo = UsersData?.usersInfo;
  const notInterestedOptions = [];

  notInterestedInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    notInterestedOptions.push(obj.value);
  });

  const options = notInterestedOptions;

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const [selectedDays, setSelectedDays] = useState([]);
  const isAllSelectedDays =
    options.length > 0 && selectedDays.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const handleChangeDays = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedDays(selectedDays.length === options.length ? [] : options);
      return;
    }
    setSelectedDays(value);
  };

  const department_Info = hospital_department_Data?.departmentInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;

  const experienceData = Experience_Data?.experienceInfo;
  const qualificationsData = Qualification_Type_Data?.qualificationInfo;
  const professionData = Profession_Type_Data?.professionInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  const companyData = hospital_company_Data?.companyInfo;

  const branchData = hospital_branch_Data?.branchInfo;

  const userInfo = UsersData?.usersInfo;

  const departmentOptions = [];
  const organizationOptions = [];
  const experienceOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];
  const professionOptions = [];
  const companyOptions = [];
  const branchOptions = [];
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  department_Info.map((ele) => {
    let obj = {
      key: ele?._id,
      value: ele?.name,
    };

    departmentOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  companyData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  //IDS
  const [organizationID, setOrganizationID] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [branchID, setBranchID] = useState("");
  const [professionID, setProfessionID] = useState("");
  const [experienceID, setExperienceID] = useState("");
  const [departmentID, setDepartmentID] = useState("");
  const [qualificationID, setQualificationID] = useState("");
  const [specializationID, setSpecializationID] = useState("");
  const [candidateTypeID, setCandidateTypeID] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");

  //DropDown Handlers
  const handleOrganizationChange = (e) => {
    setValue("organization.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setOrganizationID(userID);
  };

  const handleProfessionChange = (e) => {
    setValue("profession.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setProfessionID(userID);
  };

  const handleExperienceChange = (e) => {
    setValue("numExperience.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setExperienceID(userID);
  };

  const handleCandidateTypeChange = (e) => {
    setValue("candidateType.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCandidateTypeID(userID);
  };

  const handleSpecializationChange = (e) => {
    setValue("specialization.name", e.target.value);
    let userID;
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setSpecializationID(userID);
  };

  const handleQualificationChange = (e) => {
    setValue("qualification.name", e.target.value);
    let userID;

    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setQualificationID(userID);
  };

  const handleCompanyChange = (e) => {
    setValue("company.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    companyOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCompanyID(userID);
  };

  const handleDepartmentChange = (e) => {
    setValue("department.name", e.target.value);
    let userID;

    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setDepartmentID(userID);
  };

  const handleBranchChange = (e) => {
    setValue("branch.name", e.target.value);
    let userID;

    branchOptions.map((obj) => {
      if (obj.value === e.target.value) {
        userID = obj.key;
      }
    });

    setBranchID(userID);
  };

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setSpecialisationsType());
    dispatch(setProfessionType());
    dispatch(setQualificationType());
    dispatch(setExperience());
    dispatch(getCompanyList());
    dispatch(getDepartmentsList({}));
    dispatch(HospitalBranchList([true]));
    dispatch(setCityList());
    // dispatch(
    //   HospitalBranchList({
    //     criteria: {
    //       listName: true,
    //     },
    //   })
    // );
    dispatch(setUsersDropdown());
  }, [dispatch]);

  const formValues = getValues();
  //   console.log("1074-->",formValues)
  // console.log("1-->",getValues("createdAt"))

  const formattedCreatedAt = getValues("createdAt")
    ? dayjs(getValues("createdAt"))?.format("DD/MM/YYYY")
    : null;

  const formattedUpdatedAt = getValues("updatedAt")
    ? dayjs(getValues("updatedAt"))?.format("DD/MM/YYYY")
    : null;

  let rateOffered = responseData[0]?.cost / responseData[0]?.shiftHours;
  // console.log("rateOffered", rateOffered)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "50px" }}>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("code")}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          State
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectOptions}
                          getValues={getValues}
                          control={control}
                          name="state"
                          customOnChange={(e) =>
                            setValue("state", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Company
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={organizationOptions}
                          getValues={getValues}
                          placeholder="Organization"
                          control={control}
                          name="organization.name"
                          defaultValue="none"
                          customOnChange={handleOrganizationChange}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Corporate
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={companyOptions}
                          getValues={getValues}
                          placeholder="Company"
                          control={control}
                          name="company.name"
                          defaultValue="none"
                          customOnChange={handleCompanyChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Department
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Department Name"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={responseData[0]?.department?.name}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={departmentOptions}
                          getValues={getValues}
                          placeholder="Department"
                          control={control}
                          name="department.name"
                          defaultValue="none"
                          customOnChange={handleDepartmentChange}
                        />
                      </Grid> */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Hospital Branch
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={branchOptions}
                          placeholder="Branch"
                          control={control}
                          // {...register("branch")}
                          name="branch.name"
                          defaultValue="none"
                          getValues={getValues}
                          customOnChange={handleBranchChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Cancel Reason
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={8} sx={{ marginTop: "2rem" }}>
                      <MDButton>{responseData?.cancelReason}</MDButton>
                    </Grid> */}
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Cancel Reason"
                          sx={{ width: "100%", marginTop: "2.2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("cancelReason")}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Qualification
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={qualificationOptions}
                          placeholder="Qualification"
                          getValues={getValues}
                          control={control}
                          name="qualification.name"
                          defaultValue="none"
                          customOnChange={handleQualificationChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Num Experience
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={experienceOptions}
                          getValues={getValues}
                          placeholder="Experience"
                          control={control}
                          name="numExperience.name"
                          defaultValue="none"
                          customOnChange={handleExperienceChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Specialization
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={specializationOptions}
                          getValues={getValues}
                          placeholder="Specialization"
                          control={control}
                          name="specialization.name"
                          defaultValue="none"
                          customOnChange={handleSpecializationChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Candidate Type
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={professionOptions}
                          getValues={getValues}
                          placeholder="CandidateType"
                          control={control}
                          name="candidateType.name"
                          defaultValue="none"
                          customOnChange={handleCandidateTypeChange}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Timings Type
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={timingOptions}
                          getValues={getValues}
                          placeholder="Timings"
                          name="timingsType"
                          control={control}
                          customOnChange={(e) =>
                            setValue("timingsType", e.target.value)
                          }
                        />
                      </Grid>
                      {formValues?.timingsType === "CONSEQUTIVE" ? (
                        <>
                          {/* Consecutive Time Start Date Time */}

                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Consecutive Time Start Date Time
                            </Typography>
                          </Grid>

                          <Grid item xs={8} sx={{ marginTop: "2rem" }}>
                            <Controller
                              name={"consequtiveTime.start.datetime"}
                              control={control}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    // minDate={new Date()}
                                    {...field}
                                    value={
                                      getValues(
                                        "consequtiveTime.start.datetime"
                                      ) || null
                                      // ? dayjs(getValues(
                                      //     "consequtiveTime.start.datetime"
                                      //   )).format("DD/MM/YYYY hh:mm A")
                                      // : null
                                    }
                                    inputProps={{
                                      style: {
                                        fontSize: 15,
                                        fontWeight: "bold",
                                      },
                                    }}
                                    {...register(
                                      "consequtiveTime.start.datetime"
                                    )}
                                    onChange={(newValue) => {
                                      setValue(
                                        "consequtiveTime.start.datetime",
                                        newValue.$d.toISOString()
                                      );
                                      setValue(
                                        "consequtiveTime.end.datetime",
                                        null
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyDown={onKeyDown}
                                        autoComplete="off"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>

                          {/* Consecutive Time End Date Time */}
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Consecutive Time End Date Time
                            </Typography>
                          </Grid>

                          <Grid item xs={8} sx={{ marginTop: "2rem" }}>
                            <Controller
                              name={"consequtiveTime.end.datetime"}
                              control={control}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    minDate={
                                      getValues(
                                        "consequtiveTime.start.datetime"
                                      ) || null
                                    }
                                    {...field}
                                    value={
                                      getValues(
                                        "consequtiveTime.end.datetime"
                                      ) || null
                                    }
                                    inputProps={{
                                      style: {
                                        fontSize: 15,
                                        fontWeight: "bold",
                                      },
                                    }}
                                    {...register(
                                      "consequtiveTime.end.datetime"
                                    )}
                                    onChange={(newValue) => {
                                      setValue(
                                        "consequtiveTime.end.datetime",
                                        newValue.$d.toISOString()
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyDown={onKeyDown}
                                        autoComplete="off"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Non Consecutive Time Start Date Time
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sx={{ marginTop: "2rem" }}>
                            <Controller
                              name={"nonConsequtiveTime.start.datetime"}
                              control={control}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    {...field}
                                    inputProps={{
                                      style: {
                                        fontSize: 15,
                                        fontWeight: "bold",
                                      },
                                    }}
                                    value={
                                      getValues(
                                        "nonConsequtiveTime.start.datetime"
                                      )
                                        ? getValues(
                                            "nonConsequtiveTime.start.datetime"
                                          )
                                        : null
                                    }
                                    {...register(
                                      "nonConsequtiveTime.start.datetime"
                                    )}
                                    onChange={(newValue) => {
                                      setValue(
                                        "nonConsequtiveTime.start.datetime",
                                        newValue.$d.toISOString()
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyDown={onKeyDown}
                                        autoComplete="off"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                          {/* Non Consecutive Time End Date Time */}
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Non Consecutive Time End Date Time
                            </Typography>
                          </Grid>

                          <Grid item xs={8} sx={{ marginTop: "2rem" }}>
                            <Controller
                              name={"nonConsequtiveTime.end.datetime"}
                              control={control}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    {...field}
                                    inputProps={{
                                      style: {
                                        fontSize: 15,
                                        fontWeight: "bold",
                                      },
                                    }}
                                    value={
                                      getValues(
                                        "nonConsequtiveTime.end.datetime"
                                      )
                                        ? getValues(
                                            "nonConsequtiveTime.end.datetime"
                                          )
                                        : null
                                    }
                                    {...register(
                                      "nonConsequtiveTime.end.datetime"
                                    )}
                                    onChange={(newValue) => {
                                      setValue(
                                        "nonConsequtiveTime.end.datetime",
                                        newValue.$d.toISOString()
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyDown={onKeyDown}
                                        autoComplete="off"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Description
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=" Job Description"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("jobDescription")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          No of Jobs
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="No of Jobs"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("numberOfJobs")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          No of Days
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="No of Days"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("numberOfDays")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Min Rate
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Min Rate"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("minRate")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Max Rate
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Max Rate"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("maxRate")}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          No of Hours
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="No of Hours"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("maxRate")}
                          value={responseData[0]?.shiftHours}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Rate offered
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Max Rate"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("maxRate")}
                          value={rateOffered}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Job Location Address City
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "1rem" }}>
                        <SelectDropDownNew
                          options={cityOptions}
                          name={`branch.address.city`}
                          getValues={getValues}
                          control={control}
                          defaultValue="none"
                          setValue={setValue}
                          disabled={true}
                          placeholder="Select"
                          customOnChange={(e) => {
                            setValue(`branch.address.city`, e.target.value);
                          }}
                        />
                        {/* <MDInput
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.city")}
                            /> */}
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Professional Charges
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Professional Charges"
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("cost")}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Controller
                                  name="isFood"
                                  control={control}
                                  render={({ field: props }) => (
                                    <Checkbox
                                      {...props}
                                      checked={props.value}
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label="Is Food ?"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={3}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Controller
                                  defaultValue={false}
                                  name="isAccommodation"
                                  control={control}
                                  render={({ field: props }) => (
                                    <Checkbox
                                      {...props}
                                      checked={props.value}
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label="Is Accommodation ?"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Controller
                                  defaultValue={false}
                                  name="isTransport"
                                  control={control}
                                  render={({ field: props }) => (
                                    <Checkbox
                                      {...props}
                                      checked={props.value}
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label="Is Transport ?"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <Button
                            onClick={() => {
                              navigate(`/availbility/${ID}`);
                            }}
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Availability
                          </Button>
                        </div>
                      </Grid>
                      {/* shift logs */}
                      <Grid xs={12} sx={{ marginTop: "2rem" }}>
                        {/* <MDBox sx={{ marginTop: "2rem" }}> */}
                        <Typography>Shifts Logs</Typography>
                        <DataGrid
                          getRowId={(row) => row._id}
                          columns={columns}
                          components={{ Toolbar: CustomToolbar }}
                          rows={shiftLogRows}
                          pagination
                          pageSize={100}
                          rowsPerPageOptions={[100]}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                date: true,
                                cost: false,
                                // doneBy: false,
                                // shiftStartTime: false,
                                // shiftEndTime: false,
                                checkOutTime: true,
                                approved: true,
                              },
                            },
                          }}
                          sx={{
                            backgroundColor: "#ffff",
                            color: "grey",
                            height: "20rem",
                            margin: "1rem",
                            borderRadius: "0.5rem",
                            "& .MuiDataGrid-cell": {
                              padding: "15px",
                            },
                            borderColor: "light-grey",
                            "& .MuiDataGrid-cell:hover": {
                              color: "info",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                              backgroundColor: "#f5f5f5",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#000",
                            },
                          }}
                        />
                        {/* </MDBox> */}
                      </Grid>
                      {/* <DataGrid
                    columns={columns}
                    rows={rows}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    sx={{
                      backgroundColor: "#ffff",
                      color: "grey",
                      height: "20rem",
                      margin: "1rem",
                      borderRadius: "0.5rem",
                      "& .MuiDataGrid-cell": {
                        padding: "15px",
                      },
                      borderColor: "light-grey",
                      "& .MuiDataGrid-cell:hover": {
                        color: "info",
                      },
                    }}
                  /> */}
                      {/* </MDBox> */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address Address
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.address.address`)}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address City
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.address.city`)}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address State
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.address.state`)}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address Pin
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.address.pin`)}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address Country
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.address.country`)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Address Coords
                        </Typography>
                      </Grid>
                      {/* Coords */}
                      <Grid item xs={12}>
                        <DynamicCoords
                          control={control}
                          name="contactDetail.address.coords"
                          setValue={setValue}
                          getValues={getValues}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Email
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.email`)}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Contact Detail Phone
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: {
                              fontSize: 15,
                              fontWeight: "bold",
                            },
                          }}
                          {...register(`contactDetail.phone`)}
                        />
                      </Grid>

                      {/* Additional Skills */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography variant="subtitle1">
                          Additional Skill
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          multiline
                          rows={5}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          fullWidth
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            // rows: 6,
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={responseData[0]?.additionalSkill
                            ?.map((skill) => skill.name)
                            .join(", ")}
                        />
                      </Grid>

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedCreatedAt}
                          {...register("created_at")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedUpdatedAt}
                          {...register("updated_at")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Created By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          {...register(`createdBy.name.full`)}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Updated By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          {...register(`updatedBy.name.full`)}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>
                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default JobDetailsByJobCode;
