import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  CircularProgress,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "util/Helper";
import {
  fetchBillDetailsData,
  getFilteredBillDetails,
  uploadPaidBill,
  updateBillById,
  updateBillAmountReceived,
} from "./Redux/actionCreator";
import moment from "moment";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterData,
  updateStartDate,
  updateEndDate,
} from "./Redux/reducer";
import EditAmountRecieveDialog from "./EditAmountRecieveDialog";
import Menu from "@material-ui/core/Menu";
import authoritySignature from "assets/images/authoritySignature.png";
import IfanFlexLogo from "assets/images/ifanFlexLogo.png";
import jobizoLogo from "assets/images/jobizoLogo.png";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import JSZip from "jszip";
import RupeesSymbol from "assets/images/rupees_symbol.jpg";

const GigBillingDetails = () => {
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  const [currentFilterPage, setCurrentFilterPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [showPagination, setShowPagination] = useState(false);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const [filterReset, setFilterReset] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedOptionPaid, setSelectedOptionPaid] = useState(false);
  const [selectedOptionPaid, setSelectedOptionPaid] = useState("not paid");
  const [inputValue, setInputValue] = useState("");
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");

  const { Bill_Details_Data } = useSelector((state) => state.bills);
  // console.log(Bill_Details_Data);
  var billData = Bill_Details_Data?.billDetailsInfo;
  // console.log("Bill data", billData);

  const { Bill_Filtered_Data } = useSelector((state) => state.bills);
  const billFilteredData = Bill_Filtered_Data?.billFilteredInfo;
  // console.log("filtered bill>>>", billFilteredData);

  var billLoading = Bill_Details_Data?.billDetailsLoading;
  // console.log("Bill loading", billLoading);
  const totalCount = Bill_Details_Data?.total;
  const currentPage = useSelector((state) => state.bills.currentPage);
  const pageSize = useSelector((state) => state.bills.pageSize);
  const searchKey = useSelector((state) => state.bills.searchKey);
  const filterAll = useSelector((state) => state.bills.filterAll);
  const startDate = useSelector((state) => state.bills.startDate);
  const endDate = useSelector((state) => state.bills.endDate);

  useEffect(() => {
    setLoading(billLoading);
  }, [billLoading]);

  const navigate = useNavigate();
  const handleView = (id) => {
    navigate(`/locumBill/view/${id}`);
    // const url = `/locumBill/view/${id}`;
    // window.open(url, "_blank");
  };
  const handleView1 = (id) => {
    navigate(`/locumBill/view1/${id}`);
  };

  function getJobStartDate(param) {
    // var rowName = formatDate(param?.row?.userInvoices[0]?.date?.datetime);
    // var dateNOW = new Date(rowName);
    // return dateNOW;
    var a;
    var rowName = param?.row?.userInvoices[0]?.date?.datetime;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }
  function getJobID(params) {
    return `${params?.row?.userInvoices[0]?.jobId || "NA"}`;
  }
  function getHospitalBranch(params) {
    return `${params?.row?.buyer?.address?.branchName || "NA"}`;
  }
  function getDepartment(params) {
    return `${params?.row?.userInvoices[0]?.departmentName || "NA"}`;
  }
  function getCreatedAT(params) {
    // console.log("1-->", params)
    var a;
    const roleName = params?.row?.createdAt;
    if (roleName) {
      a = moment(roleName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   // setSelectedFileName(file.name);
  //   dispatch(uploadPaidBill(file))
  //     .then((response) => {
  //       if (response.payload.data.status === true) {
  //         const updatedPaidBills = billData?.map((item) => {
  //           // Assuming each item has an '_id' property
  //           return { ...item, isPaid: true };
  //         });
  //         setSelectedOptionPaid(true);
  //         // console.log("1--->", updatedPaidBills)
  //         // if(updatedPaidBills) {
  //         //   toast.success("Status Changed Successfully",{
  //         //     position:"top-right"
  //         //   });
  //         // }
  //         dispatch(fetchBillDetailsData(updatedPaidBills));
  //         dispatch(getFilteredBillDetails(updatedPaidBills));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading file:", error);
  //       // Handle error
  //     });
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // console.log("1-->", file)
    // setSelectedFileName(file.name);
    dispatch(uploadPaidBill(file))
      .then((response) => {
        // console.log("Response from uploadPaidBill:", response);
        if (
          response.payload &&
          response.payload.data &&
          response.payload.data.status === true
        ) {
          // console.log("File uploaded successfully.");
          dispatch(fetchBillDetailsData());
          dispatch(getFilteredBillDetails());
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };

  const handleEditAmountRecievedDetail = async (formData) => {
    // console.log("formData>>", formData)
    const { _id, amountReceived } = formData;

    try {
      // Dispatch an action to update credit limit in the backend
      const response = await dispatch(
        updateBillAmountReceived({ id: _id, payload: { amountReceived } })
      );
      if (response && response.error) {
        console.error("Failed to update credit limit:", response.error);
      } else {
        dispatch(fetchBillDetailsData([]));
        // console.log("Credit limit updated successfully");
      }
    } catch (error) {
      console.error("Error updating credit limit:", error);
    }
  };

  const columns = [
    {
      field: "billNumber",
      headerName: `Bill Number`,

      // flex: 2,
    },
    {
      field: "jobId",
      headerName: `Job ID`,
      renderCell: (param) =>
        param?.row?.userInvoices[0]?.jobId
          ? param?.row?.userInvoices[0]?.jobId
          : "NA",
      valueGetter: getJobID,
      // flex: 1.5,
    },
    {
      field: "branchName",
      headerName: `Hospital Branch`,
      renderCell: (param) =>
        param?.row?.buyer?.address?.branchName
          ? param?.row?.buyer?.address?.branchName
          : "NA",
      valueGetter: getHospitalBranch,
      // flex: 2,
    },
    {
      field: "departmentName",
      headerName: `Department`,
      renderCell: (param) =>
        param?.row?.userInvoices[0]?.departmentName
          ? param?.row?.userInvoices[0]?.departmentName
          : "NA",
      valueGetter: getDepartment,
      // flex: 1.5,
    },
    {
      field: "jobStartDate",
      headerName: `Job Start Date`,
      renderCell: (cellValues) => {
        // console.log("1-->",cellValues)
        var a;
        var rowName = cellValues?.row?.userInvoices[0]?.date?.datetime;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      type: "date",
      valueGetter: getJobStartDate,
      // flex: 2,
    },
    {
      field: "numberOfDays",
      headerName: `Number of days`,
      // flex: 2,
    },
    {
      field: "amount",
      headerName: `Amount`,
      // flex: 1.5,
    },
    // {
    //   field: "amountReceived",
    //   headerName: `Amount Received`,
    //   renderCell: (cellValues) => {
    //     // console.log("1-->",cellValues)
    //     var a;
    //     var rowName = cellValues?.row?.amountReceived
    //     if (rowName) {
    //       a = rowName
    //       return `${a}`;
    //     } else {
    //       a = "0";
    //       return `${a}`;
    //     }
    //   },
    //   // flex: 1.5,
    // },

    {
      field: "amountReceived",
      headerName: `Amount Received`,
      // headerName: (
      //   <div style={{ width: "800px" }}>Amount Received</div>
      // ),
      // renderCell: ({ row }) => (
      //   <>

      //       <>
      //         <span>{row.amountReceived.toFixed(2)}</span>
      //         <EditAmountRecieveDialog
      //           handleConfirmation={(formData) => {
      //             handleEditAmountRecievedDetail(formData);
      //           }}
      //           _id={row._id}
      //           amountReceived={row.amountReceived}
      //           render={(handlePopupOpen) => (
      //             <IconButton
      //               aria-label="edit"
      //               size="small"
      //               style={{ padding: 4, marginTop: -4, fontSize: 16 }}
      //             >
      //               <Edit fontSize="small" onClick={handlePopupOpen} />
      //             </IconButton>
      //           )}
      //         />
      //       </>

      //   </>
      // ),

      renderCell: ({ row }) => (
        <>
          {row.amountReceived !== undefined && row.amountReceived !== null ? (
            <>
              <span>{row.amountReceived.toFixed(2)}</span>
              <EditAmountRecieveDialog
                handleConfirmation={(formData) => {
                  handleEditAmountRecievedDetail(formData);
                }}
                _id={row._id}
                amountReceived={row.amountReceived}
                render={(handlePopupOpen) => (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ padding: 4, marginTop: -4, fontSize: 16 }}
                  >
                    <Edit fontSize="small" onClick={handlePopupOpen} />
                  </IconButton>
                )}
              />
            </>
          ) : (
            <>
              <span>0</span>
              <EditAmountRecieveDialog
                handleConfirmation={(formData) => {
                  handleEditAmountRecievedDetail(formData);
                }}
                _id={row._id}
                amountReceived={0}
                render={(handlePopupOpen) => (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ padding: 4, marginTop: -4, fontSize: 16 }}
                  >
                    <Edit fontSize="small" onClick={handlePopupOpen} />
                  </IconButton>
                )}
              />
            </>
          )}
        </>
      ),

      // flex: 3,
      // valueGetter: getLimit,
    },
    {
      field: "createdAt",
      type: "date",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.createdAt;
        if (roleName) {
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "View",
      headerName: `Debit Note`,

      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              aria-label="view"
              size="small"
              onClick={() => handleView(row._id)}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </>
        );
      },
      // flex: 1,
    },
    {
      field: "View2",
      headerName: `Tax Invoice`,

      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              aria-label="view"
              size="small"
              onClick={() => handleView1(row._id)}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </>
        );
      },
      // flex: 1,
    },

    {
      field: "isPaid",
      headerName: `Paid Status`,

      renderCell: (cellValues) => {
        const status = cellValues?.row?.status;

        // console.log("status>>", status)

        let selectedOptionPaidValue;

        if (status === "fully paid") {
          selectedOptionPaidValue = "Paid";
        } else if (status === "partially paid") {
          selectedOptionPaidValue = "Partially Paid";
        } else {
          selectedOptionPaidValue = "Unpaid";
        }

        return selectedOptionPaidValue;

        // const handleOptionChange = async (event) => {
        //   const selectedValue = event.target.value;

        //   // Update the local state
        //   setSelectedOptionPaid(selectedValue);

        //   const payload = {
        //     status: selectedValue === "Paid" ? "fully paid" : selectedValue === "Partially Paid" ? "partially paid" : "not paid",
        //   };

        //   console.log("1-->", payload)
        //   console.log("2-->", selectedOptionPaid)

        //   try {
        //     await dispatch(updateBillById({ payload, id: cellValues.row._id }));

        //     // Update the local item
        //     const updatedData = billData?.map((item) => {
        //       if (item?._id === cellValues.row?._id) {
        //         return { ...item, status: payload.status };
        //       }
        //       return item;
        //     });
        //     dispatch(fetchBillDetailsData(updatedData));
        //     dispatch(getFilteredBillDetails(updatedData));
        //   } catch (error) {
        //     console.error("Error updating status:", error);
        //     // Handle error if needed
        //   }
        // };

        // return (
        //   <div>
        //     <Select
        //       value={selectedOptionPaidValue}
        //       onChange={handleOptionChange}
        //       label="Payment Status"
        //       size="small"
        //       style={{ paddingRight: "20px" }}
        //     >
        //       <MenuItem value="Paid">Paid</MenuItem>
        //       <MenuItem value="Partially Paid">Partially Paid</MenuItem>
        //       <MenuItem value="Unpaid">Unpaid</MenuItem>
        //     </Select>
        //   </div>
        // );
      },

      // flex: 2,
    },

    // {
    //   field: "isPaid",
    //   headerName: `Paid Status`,

    //   renderCell: (cellValues) => {
    //     // console.log("cell", cellValues)
    //     const isPaid = cellValues?.row?.isPaid;
    //     // console.log("isPaid", isPaid);
    //     // console.log("selectedOptionPaid", selectedOptionPaid);

    //     // const selectedOptionPaidValue = selectedOptionPaid || isPaid ? "Paid" : "Unpaid";

    //     let selectedOptionPaidValue;

    //     if (selectedOptionPaid === false && isPaid === true) {
    //       selectedOptionPaidValue = "Paid";
    //     } else if (selectedOptionPaid === true && isPaid === true) {
    //       selectedOptionPaidValue = "Paid";
    //     } else if (isPaid === true) {
    //       selectedOptionPaidValue = "Paid";
    //     } else {
    //       selectedOptionPaidValue = "Unpaid";
    //     }

    //     const handleOptionChange = async (event) => {
    //       const selectedValue = event.target.value === "Paid";

    //       // Update the local state
    //       setSelectedOptionPaid(selectedValue);

    //       // const updatedIsPaidValue = selectedValue === 'Paid';
    //       const payload = {
    //         isPaid: selectedValue,
    //       };

    //       try {
    //         await dispatch(updateBillById({ payload, id: cellValues.row._id }));

    //         // Update the local item
    //         const updatedData = billData?.map((item) => {
    //           // console.log("itemId", item._id)
    //           // console.log("cellId", cellValues.row._id)
    //           if (item?._id === cellValues.row?._id) {
    //             return { ...item, isPaid: selectedValue };
    //           }
    //           return item;
    //         });
    //         dispatch(fetchBillDetailsData(updatedData));
    //         dispatch(getFilteredBillDetails(updatedData));
    //       } catch (error) {
    //         console.error("Error updating status:", error);
    //         // Handle error if needed
    //       }
    //     };

    //     // console.log("selectedOptionPaid", selectedOptionPaidValue)
    //     return (
    //       <div>
    //         <Select
    //           value={selectedOptionPaidValue}
    //           onChange={handleOptionChange}
    //           label="Payment Status"
    //           size="small"
    //           style={{ paddingRight: "20px" }}
    //         >
    //           {/* <MenuItem value="Paid">Paid</MenuItem>
    //           <MenuItem value="Unpaid">Unpaid</MenuItem> */}
    //           <MenuItem value="Paid">
    //             {selectedOptionPaidValue ? "Paid" : "Unpaid"}
    //           </MenuItem>
    //           <MenuItem value="Unpaid">
    //             {selectedOptionPaidValue ? "Unpaid" : "Paid"}
    //           </MenuItem>
    //         </Select>
    //       </div>
    //     );
    //   },
    //   // flex: 2,
    // },
  ];

  const handleClose = () => {
    setCompletionMessage("");
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentFilterPage(newPage);
  // };

  // const filterByDate = () => {
  //   if (!startDate) {
  //     setCompletionMessage("Please pick Start Date");
  //     return;
  //   }
  //   if (!endDate) {
  //     setCompletionMessage("Please pick End Date");
  //     return;
  //   }
  //   const startIndex = currentFilterPage * recordsPerPage;
  //   const endIndex = startIndex + recordsPerPage;

  //   // localStorage.setItem("startDate", startDate);
  //   // localStorage.setItem("endDate", endDate);

  //   const filteredData = billData.filter((item) => {
  //     // console.log("item>>", item);
  //     // console.log("2->", item.createdAt);

  //     if (item.createdAt === undefined || !item.createdAt || item.createdAt === "NA") {
  //       return false;
  //     }
  //     const createdAt = moment(item.createdAt);
  //     // console.log("1-->", createdAt);
  //     return (
  //       (!startDate || createdAt.isSameOrAfter(startDate, "day")) &&
  //       (!endDate || createdAt.isSameOrBefore(endDate, "day"))
  //     );
  //   });
  //   // console.log("folteredData>>>", filteredData)
  //   if (filteredData.length === 0) {
  //     setCompletionMessage("No data found for the selected date range");
  //     setShowPagination(false);
  //     setFilteredInvoiceData([]);
  //     return;
  //   }
  //   setTotalRecords(filteredData.length);
  //   const slicedData = filteredData.slice(startIndex, endIndex);

  //   setFilteredInvoiceData(slicedData);
  //   setShowPagination(true);
  // };
  // console.log("3=======>", filteredInvoiceData);

  // useEffect(filterByDate, [currentPage, invoiceData, startDate, endDate]);
  // useEffect(() => {
  //   if (filterButtonClicked) {
  //     filterByDate();
  //   }
  // }, [currentFilterPage, billData, startDate, endDate, filterButtonClicked]);

  // const handleRecordsPerPageChange = (event) => {
  //   const newRecordsPerPage = parseInt(event.target.value, 10);
  //   setRecordsPerPage(newRecordsPerPage);
  //   setCurrentFilterPage(0);
  //   filterByDate();
  // };

  const generatePDFsAndZip = async () => {
    setLoading1(true);

    if (
      filterReset ||
      !filteredInvoiceData ||
      filteredInvoiceData.length === 0
    ) {
      setCompletionMessage("No filter data available. Please apply a filter.");
      setLoading1(false);
      setFilterReset(false);
      return;
    }

    const zip = new JSZip();
    const pdfResults = [];

    try {
      for (const [index, billItem] of filteredInvoiceData.entries()) {
        const userInvoiceFilterData = billItem?.userInvoices
          ? billItem?.userInvoices
          : [];

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          hotfixes: ["px_scaling"],
          format: [1200, 800],
        });

        const pdfContent = `
          
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Page Title</title>
          <style>
        .invoiceHeader{
            display: flex;
            justify-content: space-between;
            align-content: center;
        }
        .headerContent{
            text-align-last: end;
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            height: 5px;
          }
          th {
            text-align: left;
            height: 5px;
        
          }
          .textAlignmentCenter{
            text-align-last: center;
          }
          .textAlignmentRight{
            text-align-last: end;
          }
          .textFit{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .notePoint{
            font-weight: bold;
          }
          * {
                margin:0;
                padding:0;
                font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                color:  #001f3f;
              }

        
          </style>
        </head>
        
        <body >
          <div style="max-width: fit-content; font-size: 9.7px;">
            <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
              <div class="invoiceHeader">
                <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
                <div class="headerContent">
                  <h4>Duplicate Copy</h4><br />
                  <small>Plot No.89, 3Floor, Sector 44</small><br />
                  <small>Gurgaon - 122003, Haryana (India)</small><br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <table>
                <tbody>
                  <tr>
                    <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
                      TAX INVOICE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Invoice No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>${billItem?.billNumber}</td>
                    <td>
                      ${moment(billItem?.billingDate).format("DD-MM-YYYY")}
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan="2">
                      Tax Registration
                      <br />
                      PAN:AAFCI9I9183G
                      <br />
                      GSTN:06AAFCI9183GIZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan="2">Instructions:</th>
                  </tr>
                  <tr>
                    <td>
                      ${billItem?.buyer?.address?.hospitalName}, 
                      ${billItem?.buyer?.address?.address?.address}, 
                      ${billItem?.buyer?.address?.address?.city}, 
                      ${billItem?.buyer?.address?.address?.state}, 
                      ${billItem?.buyer?.address?.address?.country}
                      <br />
                      ${billItem?.buyer?.address?.address?.pin}
                    </td>
        
                    <td rowSpan="2" colSpan="2">
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 40210500512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: ${billItem?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:${
                        billItem?.buyer?.reverseChargesApplicable
                      }
                      <br />
                      GSTIN: ${billItem?.buyer?.gst}
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">Description of Service</th>
                    <th  className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Reimbursement of Fees Payable to the
                      Professional
        
                    </td>
                    <td className="textAlignmentRight">${billItem?.cost}</td>
                  </tr>
        
                  <tr>
                    <td></td>
                    <td >TDS to be deposited to professional*</td>
                    <td className="textAlignmentRight">${billItem?.TDS}</td>
                  </tr>
        
                  <tr>
                    <td></td>
                    <td>Sub Total for reimbursements</td>
                    <td className="textAlignmentRight">${
                      billItem?.reimbursement
                    }</td>
                  </tr>
        
                  <tr>
                    <td className="textAlignmentCenter">2</td>
                    <td>Convenience Fee</td>
                    <td className="textAlignmentRight">${
                      billItem?.convensionFee
                    }</td>
                  </tr>
        
                  <tr>
                    <td></td>
                    <td>Sub Total for convenience Fee** </td>
                    <td className="textAlignmentRight">${
                      billItem?.convensionFee
                    }</td>
                  </tr>
        
                  ${
                    !billItem?.cgst || !billItem?.sgst
                      ? `
                    <tr>
                        <td colSpan="2">
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight"> </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight"> </td>
                      </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Add: IGST @ 18%</b>
                      </td>
                      <td className="textAlignmentRight">${billItem?.gst}</td>
                    </tr>
                    </>
                    
                  `
                      : `
                    <>
                      <tr>
                        <td colSpan="2">
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">${billItem?.cgst}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">${billItem?.sgst}</td>
                      </tr>
                      <tr>
                      <td colSpan="2">
                        <b>Add: IGST @ 18%</b>
                      </td>
                      <td className="textAlignmentRight"> </td>
                    </tr>
                  `
                  }
        
                  <tr>
                    <td colSpan="2">
                      <b>Total</b>
                    </td>
                    <td className="textAlignmentRight">${billItem?.amount}</td>
                  </tr>
                </tbody>
              </table>
              <div style="margin-top: 10px;">
                <b>Note <br/> * TDS on reimbursement has to be deposited against professionals PAN Number as mention in Annx. <br/> ** TDS on Convenience fee to be deposited to IFANFLEX.</b><br/><br/>
                 <p>Terms & Conditions</p>
                 <div style="padding: 20px;">
                 <p>1. Subject to Delhi Jurisdiction only</p>
                 <p>2. Please send the relevant TDS Certificate asap</p>
                 <p>3. Category of Services: Permanent placement services, other
                   than executive search services
                 </p>
                 <p>4. Service Accounting Code: ${billItem?.sac}</p>
                 <p>5. Late Payment Fees of 1.5% per month would be charged after due date</p>
                 </div>
                 <div>
                   <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
                   <img
                     src=${authoritySignature}
                     alt="logosign"
                     style="width: 10%;height: 10%;align-self: end;margin: 6px;"
                   /><br/>
                   <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
                     No Signature required as it's a system generated Invoice
                   </p>
                 </div>
                 <hr />
                 <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
                   <p>Office: +91 124.487 5800</p>
                   <p>Fax : +91 124.401 4035</p>
                   <p>CIN: U74999DL2020PTC373866</p>
                 </div>
               </div>
              <br />
            </div>
        
            <div style="page-break-before: always; margin-top: 1rem; border: 1px solid black;box-shadow: 20px;width: 800px;padding: 15px; margin: auto;">
              <div style="display: flex;justify-content: space-between;">
                <img src=${IfanFlexLogo} alt="logo" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
                <img src=${jobizoLogo} alt="logo2" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
              </div>
              <div>
                <div style="
                    background-color: grey;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    color: #ffff;
                  ">
                  INV- ${billItem?.billNumber} - Annexure
                </div>
                <table>
                  <thead>
                    <tr style="background-color: lightgrey;">
                      <th>Profession</th>
                      <th>Name</th>
                      <th>PAN Number</th>
                      <th>User Invoice No</th>
                      <th>Speciality</th>
                      <th>Shift Start Date</th>
                      <th>Shift End Date</th>
                      <th>Hours</th>
                      <th>Rate Per Hour</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${userInvoiceFilterData.map(
                      (obj) => `
                      <tr>
                        <td>${obj?.user?.professionalType}</td>
                        <td>${obj?.user?.name}</td>
                        <td>${obj?.user?.pan}</td>
                        <td>${obj?.userInvoiceNumber}</td>
                        <td>${obj?.user?.specialization}</td>
                        <td>
                          ${moment(obj?.checkinTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          ${moment(obj?.checkoutTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>${obj?.totalHours}</td>
                        <td>${obj?.ratePerHour}</td>
                        <td>${obj?.cost}</td>
                      </tr>
                    `
                    )}
                  </tbody>
                  <tr>
                    <td colspan="9">Sub Total</td>
                    <td>${billItem?.cost}</td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <b>Add: Convenience Fee @ 10% </b>
                    </td>
                    <td>${billItem?.convensionFee}</td>
                  </tr>
                  <tr>
                    <td colspan="9" style="text-align: end;">
                      <b>TOTAL</b>
                    </td>
                    <td>${billItem?.cost + billItem?.convensionFee}</td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </body>
        
        </html>
        
                  `;

        const pdfBlob = await new Promise((resolve, reject) => {
          pdf.html(pdfContent, {
            callback: (pdf) => {
              const blob = pdf.output("blob");
              resolve({ pdfBlob: blob, index });
            },
          });
        });

        pdfResults.push(pdfBlob);
      }

      pdfResults.forEach(({ pdfBlob, index }) => {
        zip.file(`Bill_${index + 1}.pdf`, pdfBlob);
      });

      // Generate ZIP file
      const content = await zip.generateAsync({ type: "blob" });

      const a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      a.download = "Bills.zip";
      a.click();

      setCompletionMessage(
        "All PDFs downloaded and wrapped in ZIP successfully!!"
      );
      setLoading1(false);
    } catch (error) {
      console.error("Error generating PDFs:", error);
      setLoading1(false);
    }
  };

  // const currentDataRangeStart = currentFilterPage * recordsPerPage + 1;
  // const currentDataRangeEnd = Math.min(
  //   (currentFilterPage + 1) * recordsPerPage,
  //   totalRecords
  // );

  // const handleResetFilter = () => {
  //   // window.location.reload();
  //   // setLocalStartDate("");
  //   // setLocalEndDate("");
  //   setStartDate("");
  //   setEndDate("");
  //   setShowPagination(false);
  //   // localStorage.removeItem("startDate");
  //   // localStorage.removeItem("endDate");
  //   setFilterReset(true);
  //   setFilteredInvoiceData([]);
  // };

  // useEffect(() => {
  //   const storedStartDate = localStorage.getItem("startDate");
  //   const storedEndDate = localStorage.getItem("endDate");

  //   // setLocalStartDate(storedStartDate || "");
  //   // setLocalEndDate(storedEndDate || "");

  //   if (storedStartDate || storedEndDate) {
  //     setStartDate(storedStartDate || "");
  //     setEndDate(storedEndDate || "");
  //     filterByDate();
  //   }
  // }, []);

  //for filter and searching only
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        fetchBillDetailsData({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          filterAll: filterAll,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterAll,
    startDate,
    endDate,
  ]);

  //for export all csv in one click
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getFilteredBillDetails({
          searchKey: searchKey,
          filterAll: filterAll,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [dispatch, searchKey, filterAll, startDate, endDate]);

  //With filter and searching for exporting as pdf and csv
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const hasAnyFilter =
        searchKey.trim() !== "" ||
        Object.values(filterAll).some((value) => value.trim() !== "") ||
        startDate.trim() !== "" ||
        endDate.trim() !== "";

      try {
        if (hasAnyFilter) {
          await dispatch(
            getFilteredBillDetails({
              searchKey: searchKey,
              filterAll: filterAll,
              startDate: startDate,
              endDate: endDate,
            })
          );
        } else {
          // If nothing is specified, you might want to handle this case
          await dispatch(
            fetchBillDetailsData({
              currentPage: currentPage,
              pageSize: pageSize,
              searchKey: searchKey,
              filterAll: filterAll,
              startDate: startDate,
              endDate: endDate,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterAll,
    startDate,
    endDate,
  ]);

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFClose = () => {
    setOpen(false);
  };

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const generatePDF = async (content, index, isDebit = false) => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      hotfixes: ["px_scaling"],
      format: [1200, 800],
    });

    const pdfBlob = await new Promise((resolve, reject) => {
      pdf.html(content, {
        callback: (pdf) => {
          const arrayBuffer = pdf.output("arraybuffer"); // Use arraybuffer instead of blob
          const blob = new Blob([arrayBuffer], { type: "application/pdf" });
          resolve({ pdfBlob: blob, index, isDebit });
        },
      });
    });

    return pdfBlob;
  };

  const handleDownloadDataWithConfirmation = () => {
    setOpen(true);
  };

  //  const handleDownloadData = async () => {
  //   setLoading1(true);
  //   setOpen(false);
  //   const zip = new JSZip();
  //   const pdfResults = [];

  //   try {
  //     for (const [index, billItem] of billData.entries()) {
  //       const userInvoiceFilterData = billItem?.userInvoices ? billItem?.userInvoices : [];

  //       const pdf = new jsPDF({
  //         orientation: "portrait",
  //         unit: "px",
  //         hotfixes: ["px_scaling"],
  //         format: [1200, 800],
  //       });

  //       const pdfContent = `

  //       <!DOCTYPE html>
  //       <html lang="en">

  //       <head>
  //         <meta charset="UTF-8">
  //         <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //         <title>Your Page Title</title>
  //         <style>
  //       .invoiceHeader{
  //           display: flex;
  //           justify-content: space-between;
  //           align-content: center;
  //       }
  //       .headerContent{
  //           text-align-last: end;
  //       }
  //       table, th, td {
  //           border: 1px solid black;
  //           border-collapse: collapse;
  //           padding: 5px;
  //           height: 5px;
  //         }
  //         th {
  //           text-align: left;
  //           height: 5px;

  //         }
  //         .textAlignmentCenter{
  //           text-align-last: center;
  //         }
  //         .textAlignmentRight{
  //           text-align-last: end;
  //         }
  //         .textFit{
  //           white-space: nowrap;
  //           text-overflow: ellipsis;
  //           overflow: hidden;
  //         }
  //         .notePoint{
  //           font-weight: bold;
  //         }
  //         * {
  //               margin:0;
  //               padding:0;
  //               font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  //               color:  #001f3f;
  //             }

  //         </style>
  //       </head>

  //       <body >
  //         <div style="max-width: fit-content; font-size: 9.7px;">
  //           <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
  //             <div class="invoiceHeader">
  //               <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
  //               <div class="headerContent">
  //                 <h4>Duplicate Copy</h4><br />
  //                 <small>Plot No.89, 3Floor, Sector 44</small><br />
  //                 <small>Gurgaon - 122003, Haryana (India)</small><br />
  //                 <small>Email: info@ifanglobal.com</small>
  //                 <hr />
  //                 <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
  //                 <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
  //                 <small>Saket Delhi New Delhi DI 110030 In</small>
  //               </div>
  //             </div>
  //             <br />
  //             <table>
  //               <tbody>
  //                 <tr>
  //                   <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
  //                     TAX INVOICE
  //                   </th>
  //                 </tr>
  //                 <tr>
  //                   <th>Service Provider</th>
  //                   <th>Invoice No.</th>
  //                   <th>Dated</th>
  //                 </tr>
  //                 <tr>
  //                   <td>IFAN Flex Pvt. Ltd.</td>
  //                   <td>${billItem?.billNumber}</td>
  //                   <td>
  //                     ${moment(billItem?.billingDate).format("DD-MM-YYYY")}

  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
  //                     122003
  //                   </td>
  //                   <td colSpan="2">
  //                     Tax Registration
  //                     <br />
  //                     PAN:AAFCI9I9183G
  //                     <br />
  //                     GSTN:06AAFCI9183GIZB
  //                     <br />
  //                     CIN:U74999DL2020PTC373866
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th>Buyer:</th>
  //                   <th colSpan="2">Instructions:</th>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     ${billItem?.buyer?.address?.hospitalName},
  //                     ${billItem?.buyer?.address?.address?.address},
  //                     ${billItem?.buyer?.address?.address?.city},
  //                     ${billItem?.buyer?.address?.address?.state},
  //                     ${billItem?.buyer?.address?.address?.country}
  //                     <br />
  //                     ${billItem?.buyer?.address?.address?.pin}
  //                   </td>

  //                   <td rowSpan="2" colSpan="2">
  //                     IfanFlex Pvt. Ltd
  //                     <br />
  //                     ICICI Bank Ltd.
  //                     <br />
  //                     Account No. : 40210500512
  //                     <br />
  //                     Branch: NDSE-I, South Extension New Delhi-110049
  //                     <br />
  //                     IFSC:ICIC0004021
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     State Code: ${billItem?.buyer?.stateCode}
  //                     <br />
  //                     Reverse charges applicable:${
  //                       billItem?.buyer?.reverseChargesApplicable
  //                     }
  //                     <br />
  //                     GSTIN: ${billItem?.buyer?.gst}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <th className="textAlignmentCenter">S.no.</th>
  //                   <th className="textAlignmentCenter">Description of Service</th>
  //                   <th  className="textAlignmentCenter">Amount</th>
  //                 </tr>
  //                 <tr>
  //                   <td className="textAlignmentCenter">1</td>
  //                   <td className="textFit">
  //                     Reimbursement of Fees Payable to the
  //                     Professional

  //                   </td>
  //                   <td className="textAlignmentRight">${billItem?.cost}</td>
  //                 </tr>

  //                 <tr>
  //                   <td></td>
  //                   <td >TDS to be deposited to professional*</td>
  //                   <td className="textAlignmentRight">${billItem?.TDS}</td>
  //                 </tr>

  //                 <tr>
  //                   <td></td>
  //                   <td>Sub Total for reimbursements</td>
  //                   <td className="textAlignmentRight">${billItem?.reimbursement}</td>
  //                 </tr>

  //                 <tr>
  //                   <td className="textAlignmentCenter">2</td>
  //                   <td>Convenience Fee</td>
  //                   <td className="textAlignmentRight">${billItem?.convensionFee}</td>
  //                 </tr>

  //                 <tr>
  //                   <td></td>
  //                   <td>Sub Total for convenience Fee** </td>
  //                   <td className="textAlignmentRight">${billItem?.convensionFee}</td>
  //                 </tr>

  //                 ${
  //                   !billItem?.cgst || !billItem?.sgst
  //                     ? `
  //                   <tr>
  //                       <td colSpan="2">
  //                         <b>Add: CGST @ 9%</b>
  //                       </td>
  //                       <td className="textAlignmentRight"> </td>
  //                     </tr>
  //                     <tr>
  //                       <td colSpan="2">
  //                         <b>Add: SGST @ 9%</b>
  //                       </td>
  //                       <td className="textAlignmentRight"> </td>
  //                     </tr>
  //                   <tr>
  //                     <td colSpan="2">
  //                       <b>Add: IGST @ 18%</b>
  //                     </td>
  //                     <td className="textAlignmentRight">${billItem?.gst}</td>
  //                   </tr>
  //                   </>

  //                 `
  //                     : `
  //                   <>
  //                     <tr>
  //                       <td colSpan="2">
  //                         <b>Add: CGST @ 9%</b>
  //                       </td>
  //                       <td className="textAlignmentRight">${billItem?.cgst}</td>
  //                     </tr>
  //                     <tr>
  //                       <td colSpan="2">
  //                         <b>Add: SGST @ 9%</b>
  //                       </td>
  //                       <td className="textAlignmentRight">${billItem?.sgst}</td>
  //                     </tr>
  //                     <tr>
  //                     <td colSpan="2">
  //                       <b>Add: IGST @ 18%</b>
  //                     </td>
  //                     <td className="textAlignmentRight"> </td>
  //                   </tr>
  //                 `
  //                 }

  //                 <tr>
  //                   <td colSpan="2">
  //                     <b>Total</b>
  //                   </td>
  //                   <td className="textAlignmentRight">${billItem?.amount}</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //             <div style="margin-top: 10px;">
  //               <b>Note <br/> * TDS on reimbursement has to be deposited against professionals PAN Number as mention in Annx. <br/> ** TDS on Convenience fee to be deposited to IFANFLEX.</b><br/><br/>
  //                <p>Terms & Conditions</p>
  //                <div style="padding: 20px;">
  //                <p>1. Subject to Delhi Jurisdiction only</p>
  //                <p>2. Please send the relevant TDS Certificate asap</p>
  //                <p>3. Category of Services: Permanent placement services, other
  //                  than executive search services
  //                </p>
  //                <p>4. Service Accounting Code: ${billItem?.sac}</p>
  //                <p>5. Late Payment Fees of 1.5% per month would be charged after due date</p>
  //                </div>
  //                <div>
  //                  <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
  //                  <img
  //                    src=${authoritySignature}
  //                    alt="logosign"
  //                    style="width: 10%;height: 10%;align-self: end;margin: 6px;"
  //                  /><br/>
  //                  <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
  //                    No Signature required as it's a system generated Invoice
  //                  </p>
  //                </div>
  //                <hr />
  //                <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
  //                  <p>Office: +91 124.487 5800</p>
  //                  <p>Fax : +91 124.401 4035</p>
  //                  <p>CIN: U74999DL2020PTC373866</p>
  //                </div>
  //              </div>
  //             <br />
  //           </div>

  //           <div style="page-break-before: always; margin-top: 1rem; border: 1px solid black;box-shadow: 20px;width: 800px;padding: 15px; margin: auto;">
  //             <div style="display: flex;justify-content: space-between;">
  //               <img src=${IfanFlexLogo} alt="logo" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
  //               <img src=${jobizoLogo} alt="logo2" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
  //             </div>
  //             <div>
  //               <div style="
  //                   background-color: grey;
  //                   text-align: center;
  //                   margin-bottom: 10px;
  //                   margin-top: 10px;
  //                   color: #ffff;
  //                 ">
  //                 INV- ${billItem?.billNumber} - Annexure
  //               </div>
  //               <table>
  //                 <thead>
  //                   <tr style="background-color: lightgrey;">
  //                     <th>Profession</th>
  //                     <th>Name</th>
  //                     <th>PAN Number</th>
  //                     <th>User Invoice No</th>
  //                     <th>Speciality</th>
  //                     <th>Shift Start Date</th>
  //                     <th>Shift End Date</th>
  //                     <th>Hours</th>
  //                     <th>Rate Per Hour</th>
  //                     <th>Total Amount</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   ${userInvoiceFilterData.map(
  //                     (obj) => `
  //                     <tr>
  //                       <td>${obj?.user?.professionalType}</td>
  //                       <td>${obj?.user?.name}</td>
  //                       <td>${obj?.user?.pan}</td>
  //                       <td>${obj?.userInvoiceNumber}</td>
  //                       <td>${obj?.user?.specialization}</td>
  //                       <td>
  //                         ${moment(obj?.checkinTime?.datetime).format("DD-MM-YYYY")}
  //                       </td>
  //                       <td>
  //                         ${moment(obj?.checkoutTime?.datetime).format("DD-MM-YYYY")}
  //                       </td>
  //                       <td>${obj?.totalHours}</td>
  //                       <td>${obj?.ratePerHour}</td>
  //                       <td>${obj?.cost}</td>
  //                     </tr>
  //                   `
  //                   ).join('')}
  //                 </tbody>
  //                 <tr>
  //                   <td colspan="9">Sub Total</td>
  //                   <td>${billItem?.cost}</td>
  //                 </tr>
  //                 <tr>
  //                   <td colspan="9">
  //                     <b>Add: Convenience Fee @ 10% </b>
  //                   </td>
  //                   <td>${billItem?.convensionFee}</td>
  //                 </tr>
  //                 <tr>
  //                   <td colspan="9" style="text-align: end;">
  //                     <b>TOTAL</b>
  //                   </td>
  //                   <td>${(billItem?.cost + billItem?.convensionFee).toFixed(2)}</td>
  //                 </tr>
  //               </table>
  //             </div>

  //           </div>
  //         </div>
  //       </body>

  //       </html>

  //                 `;

  //       const pdfBlob = await new Promise((resolve, reject) => {
  //         pdf.html(pdfContent, {
  //           callback: (pdf) => {
  //             const blob = pdf.output("blob");
  //             resolve({ pdfBlob: blob, index });
  //           },
  //         });
  //       });

  //       pdfResults.push(pdfBlob);
  //     }

  //     pdfResults.forEach(({ pdfBlob, index }) => {
  //       zip.file(`Bill_${index + 1}.pdf`, pdfBlob);
  //     });

  //     // Generate ZIP file
  //     const content = await zip.generateAsync({ type: "blob" });

  //     const a = document.createElement("a");
  //     a.href = URL.createObjectURL(content);
  //     // a.download = "Bills.zip";
  //     a.download = `BulkBills_${formatDate()}.zip`;
  //     a.click();

  //     setCompletionMessage("All PDFs downloaded and wrapped in ZIP successfully!!");
  //     setLoading1(false);
  //   } catch (error) {
  //     console.error("Error generating PDFs:", error);
  //     setLoading1(false);
  //   }
  // };

  const handleDownloadData = async () => {
    setLoading1(true);
    setOpen(false);
    const zip = new JSZip();
    const pdfResults = [];

    // console.log("billData", billData)

    try {
      for (const [index, billItem] of billData.entries()) {
        const userInvoiceFilterData = billItem?.userInvoices
          ? billItem?.userInvoices
          : [];

        const Part1 = billItem?.billNumber.split("/").slice(-3, -2)[0];
        const Part2 = billItem?.billNumber
          .split("/")
          .slice(-2, -1)[0]
          .replace("-", "");
        const Part3 = billItem?.billNumber.split("/").pop();
        const transformedPart = isNaN(Part3) ? Part3 : "D" + Part3;
        const result = Part1 + "/" + Part2 + "/" + transformedPart;
        // console.log("1",result);
        // let debitNumber = gigBillData[0]?.billNumber;
        let debitNumber = result || "NA";
        let invoiceAmount =
          billItem?.convensionFee +
          billItem?.cgst +
          billItem?.sgst +
          billItem?.gst;
        // console.log("1-->", invoiceAmount);
        let rupeesAmount = "₹ " + invoiceAmount.toLocaleString("en-IN");
        // console.log("2-->", rupeesAmount);

        // console.log(lastPart);

        // First PDF Content
        const pdfContent = `
        
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Page Title</title>
          <style>
        .invoiceHeader{
            display: flex;
            justify-content: space-between;
            align-content: center;
        }
        .headerContent{
            text-align-last: end;
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            height: 5px;
          }
          th {
            text-align: left;
            height: 5px;
        
          }
          .textAlignmentCenter{
            text-align-last: center;
          }
          .textAlignmentRight{
            text-align-last: end;
          }
          .textFit{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .notePoint{
            font-weight: bold;
          }
          * {
                margin:0;
                padding:0;
                font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                color:  #001f3f;
              }
              .hr-line {
width: 100%;
margin: 0.5em 0;
border-top: 1px solid #000;
}
  
        
          </style>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
        </head>
        
        <body >
          <div style="max-width: fit-content; font-size: 12.7px;">
            <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
              <div class="invoiceHeader">
                <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
                <div class="headerContent">
                  <h4>Duplicate Copy</h4><br />
                  <small>Plot No.89, 3Floor, Sector 44</small><br />
                  <small>Gurgaon - 122003, Haryana (India)</small><br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <table>
                <tbody>
                  <tr>
                    <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
                      COMMERICAL DEBIT NOTE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Debit Note No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>${debitNumber}</td>
                    <td>
                      ${moment(billItem?.billingDate).format("DD-MM-YYYY")}
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan="2">
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan="2">Instructions:</th>
                  </tr>
                  <tr>
                    <td>
                      ${billItem?.buyer?.address?.hospitalName}, <br /> 
                      ${billItem?.buyer?.address?.address?.address
                        .split(", ")
                        .slice(0, 3)
                        .join(", ")},<br />
                      ${billItem?.buyer?.address?.address?.address
                        .split(", ")
                        .slice(3)
                        .join(", ")},<br /> 
                      ${billItem?.buyer?.address?.address?.city}, 
                      ${billItem?.buyer?.address?.address?.state}, 
                      ${billItem?.buyer?.address?.address?.country},
                      ${billItem?.buyer?.address?.address?.pin}
                    </td>
        
                    <td rowSpan="1" colSpan="2">
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: ${billItem?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:${
                        billItem?.buyer?.reverseChargesApplicable
                      }
                      <br />
                      GSTIN: ${billItem?.buyer?.gst}
                    </td>
                    <td style="vertical-align: top;">
                      Reference Invoice No: <hr class="hr-line" />
                      Reference Invoice Date: <hr class="hr-line" />
                      Payment Due Date:
                    </td>
                    <td style="vertical-align: top;">
                      ${billItem?.billNumber} <hr class="hr-line" />
                      ${moment(billItem?.billingDate).format(
                        "DD-MM-YYYY"
                      )} <hr class="hr-line" />
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">Description of Service</th>
                    <th  className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Reimbursement of Fees <br /> Payable to the
                      Professional
        
                    </td>
                    <td className="textAlignmentRight">${billItem?.cost}</td>
                  </tr>
        
                  <tr>
                    <td></td>
                    <td >Total</td>
                    <td className="textAlignmentRight">${(billItem?.cost).toFixed(
                      2
                    )}</td>
                  </tr>
                </tbody>
              </table>
              <div style="margin-top: 10px;">
                <b>Note <br/> * TDS is not be deducted on reimbursement amount</b><br/><br/>
                 <p>Terms & Conditions</p>
                 <div style="padding: 20px;">
                
                 <p>1. Subject to Delhi Jurisdiction only</p>
      <p>2. Late Payment Fees of 1.5% per month would be charged after due date</p>
                 </div>
                 <div>
                   <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
                   <img
                     src=${authoritySignature}
                     alt="logosign"
                     style="width: 10%;height: 10%;align-self: end;margin: 6px;"
                   /><br/>
                   <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
                    No Signature required as it's a system generated Commerical Debit Note
                   </p>
                 </div>
                 <hr />
                 <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
                   <p>Office: +91 124.487 5800</p>
                   <p>Fax : +91 124.401 4035</p>
                   <p>CIN: U74999DL2020PTC373866</p>
                 </div>
               </div>
              <br />
            </div>
        
            <div style="page-break-before: always; margin-top: 1rem; border: 1px solid black;box-shadow: 20px;width: 800px;padding: 15px; margin: auto;">
              <div style="page-break-before: always; display: flex;justify-content: space-between;">
                <img src=${IfanFlexLogo} alt="logo" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
                <img src=${jobizoLogo} alt="logo2" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
              </div>
              <div>
                <div style="
                    background-color: grey;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    color: #ffff;
                  ">
                  INV- ${billItem?.billNumber} - Annexure
                </div>
                <table>
                  <thead>
                    <tr style="background-color: lightgrey;">
                      <th>Profession</th>
                      <th>Name</th>
                      <th>PAN Number</th>
                      <th>User Invoice No</th>
                      <th>Speciality</th>
                      <th>Shift Start Date</th>
                      <th>Shift End Date</th>
                      <th>Hours</th>
                      <th>Rate Per Hour</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${userInvoiceFilterData
                      .map(
                        (obj) => `
                      <tr>
                        <td>${obj?.user?.professionalType}</td>
                        <td>${obj?.user?.name}</td>
                        <td>${obj?.user?.pan}</td>
                        <td>${obj?.userInvoiceNumber}</td>
                        <td>${obj?.user?.specialization}</td>
                        <td>
                          ${moment(obj?.checkinTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          ${moment(obj?.checkoutTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>${obj?.totalHours}</td>
                        <td>${obj?.ratePerHour}</td>
                        <td>${obj?.cost}</td>
                      </tr>
                    `
                      )
                      .join("")}
                  </tbody>
                  <tr>
                    <td colspan="9" style="text-align: end;">
                      <b>TOTAL</b>
                    </td>
                    <td>${(billItem?.cost).toFixed(2)}</td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </body>
        
        </html>
        
                  `; // Replace with your first PDF content
        const pdfBlob = await new Promise((resolve, reject) => {
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            hotfixes: ["px_scaling"],
            format: [1200, 800],
          });
          pdf.html(pdfContent, {
            callback: (pdf) => {
              const blob = pdf.output("blob");
              resolve({ pdfBlob: blob, index, isDebit: false });
            },
          });
        });

        pdfResults.push(pdfBlob);
        // }

        // Second PDF Content
        const pdfContent2 = `
        
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Page Title</title>
          <style>
        .invoiceHeader{
            display: flex;
            justify-content: space-between;
            align-content: center;
        }
        .headerContent{
            text-align-last: end;
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            height: 5px;
          }
          th {
            text-align: left;
            height: 5px;
        
          }
          .textAlignmentCenter{
            text-align-last: center;
          }
          .textAlignmentRight{
            text-align-last: end;
          }
          .textFit{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .notePoint{
            font-weight: bold;
          }
          * {
                margin:0;
                padding:0;
                font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                color:  #001f3f;
              }
              .hr-line {
width: 100%;
margin: 0.5em 0;
border-top: 1px solid #000;
}
  
        
          </style>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
        </head>
        
        <body >
          <div style="max-width: fit-content; font-size: 9.7px;">
            <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
              <div class="invoiceHeader">
                <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
                <div class="headerContent">
                  <h4>Duplicate Copy</h4><br />
                  <small>Plot No.89, 3Floor, Sector 44</small><br />
                  <small>Gurgaon - 122003, Haryana (India)</small><br />
                  <small>Email: info@jobizo.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <table>
                <tbody>
                  <tr>
                    <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
                      TAX INVOICE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Invoice No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>${billItem?.billNumber}</td>
                    <td>
                      ${moment(billItem?.billingDate).format("DD-MM-YYYY")}
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Official Address:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan="2">
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Client:</th>
                    <th colSpan="2">Bank Details:</th>
                  </tr>
                  <tr>
                    <td>
                      ${billItem?.buyer?.address?.hospitalName} </br>
                    Official Address:
                      ${billItem?.buyer?.address?.address?.address}, 
                      ${billItem?.buyer?.address?.address?.city}, 
                      ${billItem?.buyer?.address?.address?.state}, 
                      ${billItem?.buyer?.address?.address?.country}
                      <br />
                      ${billItem?.buyer?.address?.address?.pin}
                    </td>
        
                    <td rowSpan="1" colSpan="2">
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: ${billItem?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:${
                        billItem?.buyer?.reverseChargesApplicable
                      }
                      <br />
                      GSTIN: ${billItem?.buyer?.gst}
                    </td>
                    <td style="vertical-align: top;">
                      Reference Debit No: <hr class="hr-line" />
                      Reference Debit Date: <hr class="hr-line" />
                      Payment Due Date:
                    </td>
                    <td style="vertical-align: top;">
                      ${debitNumber} <hr class="hr-line" />
                      ${moment(billItem?.billingDate).format(
                        "DD-MM-YYYY"
                      )} <hr class="hr-line" />
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">Description of Service</th>
                    <th  className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Convenience Fee
        
                    </td>
                    <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${
      billItem?.convensionFee
    }</span>
</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Sub Total </td>
                    
                    <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${
      billItem?.convensionFee
    }</span>
</td>
                  </tr>
        
                  ${
                    !billItem?.cgst || !billItem?.sgst
                      ? `
                    <tr>
                        <td colSpan="2">
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight"> </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight"> </td>
                      </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Add: IGST @ 18%</b>
                      </td>
                     
                      <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${billItem?.gst}</span>
</td>
                    </tr>
                    </>
                    
                  `
                      : `
                    <>
                      <tr>
                        <td colSpan="2">
                          <b>Add: CGST @ 9%</b>
                        </td>
                       
                        <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${billItem?.cgst}</span>
</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${billItem?.sgst}</span>
</td>
                      </tr>
                      <tr>
                      <td colSpan="2">
                        <b>Add: IGST @ 18%</b>
                      </td>
                      <td className="textAlignmentRight"> </td>
                    </tr>
                  `
                  }
        
                  <tr>
                    <td colSpan="2">
                      <b>Total</b>
                    </td>
                    <td style="text-align: right; margin: 0px; padding: 0px">
    <img src=${RupeesSymbol} alt="Rupees Symbol" style="height: 17px; width: 17px; display: inline-block; vertical-align: middle;" />
    <span style="display: inline-block; vertical-align: middle;">${invoiceAmount}</span>
</td>

                  </tr>
                </tbody>
              </table>
              <div style="margin-top: 10px;">
                <b>Note <br/> * TDS to be deducted at @ ${
                  billItem?.branch?.tds ? billItem?.branch?.tds : 10
                }% under 194C</b><br/><br/>
                 <p>Terms & Conditions</p>
                 <div style="padding: 10px;">
                 
                 <p>1. Subject to Delhi Jurisdiction only</p>
                 <p>2. TDS certificate to be shared as soon as as it is
                   generated
                 </p>
                 <p>3. Service Accounting Code: ${billItem?.sac}</p>
                 <p>4. Late Payment Fees of 1.5% per month would be charged
                   after due date
                 </p>
                 <p>5. Invoice raised will be deemed correct and accepted
                   by you, unless any discrepancy is reported to us in written,
                   within 7 days from the date of receipt of the Invoice.
                 </p>
                 
                 </div>
                 <div>
                   <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
                   <img
                     src=${authoritySignature}
                     alt="logosign"
                     style="width: 10%;height: 10%;align-self: end;margin: 6px;"
                   /><br/>
                   <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
                    No Signature required as it's a system generated Invoice
                   </p>
                 </div>
                 <hr />
                 <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
                   <p>Office: +91 124.487 5800</p>
                   <p>Fax : +91 124.401 4035</p>
                   <p>CIN: U74999DL2020PTC373866</p>
                 </div>
               </div>
              <br />
            </div>
          </div>
        </body>
        
        </html>
        
                  `; // Replace with your second PDF content
        const pdfBlob1 = await new Promise((resolve, reject) => {
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            hotfixes: ["px_scaling"],
            format: [1200, 800],
          });
          pdf.html(pdfContent2, {
            callback: (pdf) => {
              const blob = pdf.output("blob");
              resolve({ pdfBlob: blob, index, isDebit: true });
            },
          });
        });

        pdfResults.push(pdfBlob1);
      }

      await Promise.all(
        pdfResults.map(async ({ pdfBlob, index, isDebit }) => {
          const billNumbers = billData[index]?.billNumber;
          const lastPart = billNumbers.split("/").pop();
          const blob = await new Response(pdfBlob).arrayBuffer();
          const updatedBlob = new Blob([blob], { type: "application/pdf" });
          // zip.file(
          //   `D${lastPart}${isDebit ? "billNumbers" : ""}.pdf`,
          //   updatedBlob
          // );
          let fileName = "";

          if (isDebit) {
            fileName += lastPart;
          } else {
            fileName = `D${lastPart}`;
          }

          zip.file(`${fileName}.pdf`, updatedBlob);
        })
      );
      const content = await zip.generateAsync({ type: "blob" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      a.download = `BulkBills_${formatDate()}.zip`;
      a.click();

      setCompletionMessage(
        "All PDFs downloaded and wrapped in ZIP successfully!!"
      );
      setLoading1(false);
    } catch (error) {
      console.error("Error generating PDFs:", error);
      setLoading1(false);
    }
  };

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadDataCSV = async () => {
    try {
      // console.log("filter-->", filteredUserData)

      // if(filteredJobData && filteredJobData.length > 0){
      const response = billFilteredData;
      // console.log("1405-->",response)
      const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // link.download = 'Jobizo Super Admin.csv';
      link.download = `Bills-${formatDate()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const handleFilterChange = (key, value) => {
    dispatch(filterData({ ...filterAll, [key]: value }));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate)); // Dispatch action to update startDate in Redux store
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadOptionClick = (option) => {
    setSelectedOption1(option);
    handleCloseMenu();
    // Add logic for PDF or CSV download based on the selected option
    console.log("Downloading as", option);
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    // dispatch(searchKeyword(sanitizedSearchKey));
    if (sanitizedSearchKey) {
      dispatch(searchKeyword(sanitizedSearchKey));
    } else {
      dispatch(updateStartDate(inputStartDate));
      dispatch(updateEndDate(inputEndDate));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title1="Locum Bills"
        showDialog={false}
        showisDeleted={false}
      />
      <Snackbar
        open={completionMessage !== ""}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
        >
          {completionMessage}
        </MuiAlert>
      </Snackbar>
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
        <MenuItem value="billNumber">Bill Number</MenuItem>
        <MenuItem value="JobId">Job ID</MenuItem>
        <MenuItem value="HospitalBranch">Hospital Branch</MenuItem>
        <MenuItem value="DepartmentName">Department Name</MenuItem>
        <MenuItem value="invoiceNumber">Invoice Number</MenuItem>
      </Select>
      {selectedOption && (
        <>
          <TextField
            label="Enter Value"
            value={filterAll[selectedOption] || ""}
            onChange={(e) => handleFilterChange(selectedOption, e.target.value)}
            fullWidth
            style={{ marginLeft: "20px", width: "150px" }}
          />
        </>
      )}
      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          // value={startDate}
          // onChange={(e) => handleStartDateChange(e.target.value)}
          value={inputStartDate}
          onChange={(e) => setInputStartDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          // value={endDate}
          // onChange={(e) => handleEndDateChange(e.target.value)}
          value={inputEndDate}   
          onChange={(e) => setInputEndDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
      </>
      {/* </div> */}
      {/* <Button
        onClick={handleDownloadDataWithConfirmation}
        variant="contained"
        color="primary"
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
        }}
      >
        Export
      </Button> */}

      <>
        <Button
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            right: "280px",
            height: "44px",
          }}
        >
          {selectedOption1 ? `Download as ${selectedOption1}` : "Export"}
        </Button>

        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDownloadDataWithConfirmation}>
            Download as PDF
          </MenuItem>
          <MenuItem onClick={() => handleDownloadDataCSV()}>
            Download as CSV
          </MenuItem>
        </Menu>
      </>

      <Dialog open={open} onClose={handleFClose}>
        <DialogTitle>Export Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only 25 exports are allowed at a time. This might take some time as
            the data requested is large..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadData} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Button
        // onClick={handleDownloadData}
        // onClick={() => {
        //   handleDownloadData()
        //   alert('Please do not generate more than 25 bills at once.');
        // }}
        onClick={() => {
          const confirmation = window.confirm(
            'Only 25 exports are allowed at a time. This might take some time as the data requested is large.'
          );
      
          if (confirmation) {
            handleDownloadData();
            // alert('Thanks for clicking!');
          } else {
            // alert('Export canceled.');
          }
        }}
        variant="contained"
        color="primary"
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
        }}
      >
        Export
      </Button> */}
      <>
        <TextField
          label={`Search by BillNumber,JobId,HospitalBranch`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by BillNumber,JobId,HospitalBranch`}
        value={searchKey}
        onChange={(e) => dispatch(searchKeyword(e.target.value))}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      {loading1 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <span style={{ fontSize: "15px", marginRight: "5px" }}>
            Please wait a few seconds...
          </span>
          <CircularProgress size={30} thickness={5} />
        </div>
      ) : completionMessage && !loading1 ? (
        <div style={{ marginLeft: "10px", color: "green" }}>
          {completionMessage}
        </div>
      ) : null}

      <div style={{ position: "absolute", right: "20px", marginTop: "1rem" }}>
        {/* <h2>Upload Dialog</h2> */}
        <input type="file" onChange={handleFileUpload} accept=".xlsx" />
        {/* <button onClick={handleUploadDialogClose}>Close</button> */}
      </div>

      <MDBox mt={8} className="marginOnTop">
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: currentDate,
              }}
              style={{ marginLeft: "10px" }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: currentDate,
              }}
              style={{ marginLeft: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                filterByDate();
                setFilterButtonClicked(true);
                setCurrentFilterPage(0);
                
              }}
              style={{ marginLeft: "10px" }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetFilter}
              style={{ marginLeft: "10px" }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={generatePDFsAndZip}
              style={{ marginLeft: "10px" }}
              disabled={loading1}
            >
              Export as PDF
            </Button>
            {/* {filterReset && (
              <Typography variant="body2" color="error">
                Please apply a filter before exporting as PDF.
              </Typography>
            )} */}
        {/* {loading1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <span style={{ fontSize: "12px", marginRight: "5px" }}>
                  Please wait a few seconds...
                </span>
                <CircularProgress size={30} thickness={5} />
              </div>
            ) : completionMessage && !loading1 ? (
              <div style={{ marginLeft: "10px", color: "green" }}>
                {completionMessage}
              </div>
            ) : null}

            {showPagination && (
              <>
                <div className="pagination">
                  <Button
                    disabled={currentFilterPage === 0}
                    onClick={() => handlePageChange(currentFilterPage - 1)}
                    // style={currentPage === 0 ? { pointerEvents: 'none', backgroundColor: '#ccc' } : {}}
                  >
                    Previous
                  </Button>
                  <span>
                    Data {currentDataRangeStart}-{currentDataRangeEnd} of{" "}
                    {totalRecords}
                  </span>
                  <Button
                    disabled={
                      currentPage ===
                        Math.ceil(totalRecords / recordsPerPage) - 1 ||
                      !filteredInvoiceData
                    }
                    onClick={() => handlePageChange(currentFilterPage + 1)}
                    // style={
                    //   currentPage === Math.ceil(totalRecords / recordsPerPage) - 1
                    //     ? { pointerEvents: 'none', backgroundColor: '#ccc' }
                    //     : {}
                    // }
                  >
                    Next
                  </Button>
                </div>
                <div>
                  <label>Show</label>
                  <select
                    value={recordsPerPage}
                    onChange={handleRecordsPerPageChange}
                    onClick={filterByDate}
                    style={{
                      marginLeft: "5px",
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      color: "navy",
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </>
            )} */}
        {/* </div>
        </div> */}
        {/* {filteredInvoiceData.length > 0 ? (
          <DataGrid
          getRowId={(row) => row?._id}
          // loading={billLoading ? true : false}
          loading={loading && <CircularProgress />}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          density="compact"
          // rows={filteredInvoiceData}
          // rowCount={totalRecords}

          rows={getPaginatedData()}
          pageSize={recordsPerPage}
          pagination={false} // Disable built-in pagination
          rowCount={totalRecords}
          page={currentFilterPage}
          onPageChange={(newPage) => setCurrentFilterPage(newPage)}
          rowsPerPageOptions={[10, 25, 50]}
          
          //isRowSelectable
          getRowHeight={() => "auto"}
          sx={{
            backgroundColor: "#ffff",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
            },

            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          // pagination
          //pageSize={100}
          //rowsPerPageOptions={[100]}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                createdAt: true,
              },
            },
          }}
        />
        ): (
          <DataGrid
          getRowId={(row) => row?._id}
          // loading={billLoading ? true : false}
          loading={loading && <CircularProgress />}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          density="compact"
          // pageSize={100}
          // rowsPerPageOptions={[100]}
          // rows={[...billData].reverse()}
          
          rows={[...billData].reverse()}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          getRowHeight={() => "auto"}
          sx={{
            backgroundColor: "#ffff",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
            },

            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          // pagination
          //pageSize={100}
          //rowsPerPageOptions={[100]}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                createdAt: true,
              },
            },
          }}
        />
        )} */}
        <DataGrid
          getRowId={(row) => row?._id}
          // loading={billLoading ? true : false}
          loading={loading && <CircularProgress />}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              // csvOptions: {
              //   fileName: `Bills-${formatDate()}`,
              // },
            },
          }}
          density="compact"
          // pageSize={100}
          // rowsPerPageOptions={[100]}
          // rows={[...billData].reverse()}

          rows={[...billData]}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          // pagination={!filteredInvoiceData.length > 0}
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          getRowHeight={() => "auto"}
          sx={{
            backgroundColor: "#ffff",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },

            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          // pagination
          //pageSize={100}
          //rowsPerPageOptions={[100]}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                createdAt: true,
              },
            },
          }}
          disableColumnFilter
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default GigBillingDetails;
