import { Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, IconButton, styled } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getUsers } from "services/userServices";
import { getreduxAvalibilityList } from "./Redux/actionCreator";
import { getPermanentApplicationList } from "./Redux/actionCreator";
import { toast } from "react-toastify";
// import {
//   pageChanged,
//   pageLimit,
// } from "./Redux/reducer";
import { Box } from "@mui/system";
import { capitalize } from '@mui/utils';

function PermanentApplication() {
  const { state } = useLocation();
  const { id } = useParams();
  // console.log(id, "Id");
  const dispatch = useDispatch();

  function getPermApplicationId(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getJobId(params) {
    return `${params?.row?.permanentJobDetails?.code || "NA"}`;
  }

  function getJobStatus(params) {
    return `${params?.row?.permanentJobDetails?.state || "NA"}`;
  }

  function getProfessionalName(params) {
    return `${params?.row?.professionalDetails?.name?.full || "NA"}`;
  }

  
  function getApplicationStatus(params) {
    return `${params?.row?.state || "NA"}`;
  }

  function getClientName(params) {
    return `${params?.row?.permanentJobDetails?.client?.name || "NA"}`;
  }

  function getCity(params) {
    return `${params?.row?.permanentJobDetails?.address?.city || "NA"}`;
  }

  function getType(params) {
    return `${params?.row?.permanentJobDetails?.type || "NA"}`;
  }

  
  function getCreatedAt(params) {
    const roleName = params?.row?.createdAt;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
    //  var a;
    // var rowName = params?.row?.createdAt;
    // if (rowName) {
    //   a = moment(rowName).format("YYYY/MM/DD");
    //   return `${a}`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
  }
  function getUpdatedAt(params) {
    const roleName = params?.row?.updatedAt;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }

  function getCreatedBy(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }
  function getUpdatedBy(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }

function getPhoneNumber(params){
  var a;
  var rowName = params?.row?.professionalDetails?.phoneNumbers;
  // console.log("rowName", rowName.length)
  if (rowName) {
    a = params?.row?.professionalDetails?.phoneNumbers[0]?.number
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
}

function getCV(params){
  var a;
  var rowName = params?.row?.professionalDetails?.documents
  if (rowName) {
    a = params?.row?.professionalDetails?.documents[0]?.files[0]?.url
  } 
  return a ? `${a}` : "NA";
}
 

  const columns = [
    {
      field: "ID",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const Id = cellValues?.row?._id;
        // return roleName
        return <Link to={`/permanent/application/${Id}`}>{Id}</Link>;
      },
      flex: 1,
      valueGetter: getPermApplicationId,
    },
    {
      field: "Job ID",
      headerName: `Job ID`,
      renderCell: (cellValues) => {
        // console.log("1-->", cellValues)
        // const roleName = cellValues?.row?.permanentJobDetails?.code;
        // return roleName;
        const rowCode = cellValues?.row?.permanentJobDetails?.code;
        const rowId = cellValues?.row?.permanentJobDetails?._id
        // return roleCode;
        return <Link to={`/permanent/job/code/${rowCode}/${rowId}`}>{rowCode}</Link>;
      },
      flex: 1,
      valueGetter: getJobId,
    },

    {
      field: "Job Status",
      headerName: `Job Status`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.permanentJobDetails?.state;
        return rowName;
      },
      flex: 1,
      valueGetter: getJobStatus,
    },

    {
      field: "Professional Name",
      headerName: ` Professional Name`,
      renderCell: (cellValues) => {
        // console.log("1-->", cellValues)
        // const rowName = cellValues?.row?.professionalDetails?.name?.full;
        // return rowName;
        const Professional = cellValues?.row?.professionalDetails;
        return (
          <Link to={`/users/name/${Professional?._id}`}>
            {Professional?.name?.full}
          </Link>
        );
      },
      flex: 1.5,
      valueGetter: getProfessionalName,
    },

    {
      field: "mobileNumber",
      headerName: `Mobile Number`,
      renderCell: (cellValues) => {
        // console.log("cellValues", cellValues?.row?.professionalDetails?.phoneNumbers)
        var a;
        var rowName = cellValues?.row?.professionalDetails?.phoneNumbers;
        // console.log("rowName", rowName.length)
        if (rowName) {
          a = cellValues?.row?.professionalDetails?.phoneNumbers[0]?.number
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      valueGetter: getPhoneNumber,

      flex: 1.5,
    },

    {
      field: "Application Status",
      headerName: `Application Status`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.state;
        return roleName;
      },
      flex: 1.5,
      valueGetter: getApplicationStatus,
    },

    {
      field: "Client Name",
      headerName: "Client Name",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.permanentJobDetails?.client?.name;
        return roleName;
      },
      flex: 1,
      valueGetter: getClientName,
    },

    {
      field: "City",
      headerName: "City",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.permanentJobDetails?.address?.city;

        return roleName;
      },
      flex: 1,
      valueGetter: getCity,
    },
    {
      field: "type",
      headerName: "Job Type",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.permanentJobDetails?.type;
        return roleName;
      },
      flex: 1,
      valueGetter: getType,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.createdAt;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updatedAt;

        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getUpdatedAt,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.createdBy?.name?.full;
        return roleName;
      },
      valueGetter: getCreatedBy,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updatedBy?.name?.full;
        return roleName;
      },
      valueGetter: getUpdatedBy,
    },
    {
      field: "resume",
      headerName: `View CV`,

      renderCell: ({ row }) => {
        // console.log("row",row)
        var a;
        var rowName = row?.professionalDetails?.documents;
        // console.log("rowName", rowName.length)
        if(rowName){
          a = row?.professionalDetails?.documents[0]?.files[0]?.url
        }
        // console.log("a",a)
        return (
          <IconButton
            aria-label="view"
            size="small"
            onClick={() => {
              if (a) {
                window.open(a, "_blank");
              } else {
                  toast.error("No CV available for this user.",{
                    position:"top-right"
                  });
              }
            }}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        );
      },
      valueGetter: getCV,
      // flex: 2,
    },
  ];

  const [isOpen, setisOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  // const [loading, setLoading] = useState(false);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);

      const response = await getUsers(checked);
      setDeletedRecords(response?.data?.data);
    } else {
      setShowDeletedRecords(false);

      const response = await getUsers(checked);
    }
  };

  const { Permanent_Application_Data } = useSelector((state) => state.availability);
  const softwares = Permanent_Application_Data?.Permanent_Application_DataInfo;
  // console.log("softwares", softwares)

  const { Availabilitys_Data } = useSelector((state) => state.availability);
  const users = Availabilitys_Data?.Availabilitys_DataInfo;

  var permanentLoading = Permanent_Application_Data?.Permanent_Application_DataLoading;

  useEffect(() => {
    setLoading(permanentLoading);
  }, [permanentLoading]);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getreduxAvalibilityList({ jobId: id }));
  //   } else {
  //     dispatch(getreduxAvalibilityList());
  //   }
  // }, []); 

  useEffect(() => {
   
      dispatch(getPermanentApplicationList());
    
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     if(id){
  //       await dispatch(getreduxAvalibilityList({ jobId: id }));  
  //     }else{
  //       await dispatch(
  //         getreduxAvalibilityList({
  //           currentPage: currentPage,
  //           pageSize: pageSize,
  //         })
  //       );
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  // ]);

  // const softwares = Availabilitys_Data?.Availabilitys_DataInfo;
  // console.log("251-->", softwares)
  // const StyledGridOverlay = styled("div")(({ theme }) => ({
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100%",
  //   "& .ant-empty-img-1": {
  //     fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  //   },
  //   "& .ant-empty-img-2": {
  //     fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  //   },
  //   "& .ant-empty-img-3": {
  //     fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  //   },
  //   "& .ant-empty-img-4": {
  //     fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  //   },
  //   "& .ant-empty-img-5": {
  //     fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
  //     fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  //   },
  // }));
  // function CustomNoRowsOverlay() {
  //   return (
  //     <StyledGridOverlay>
  //       <svg
  //         width="120"
  //         height="100"
  //         viewBox="0 0 184 152"
  //         aria-hidden
  //         focusable="false"
  //       >
  //         <g fill="none" fillRule="evenodd">
  //           <g transform="translate(24 31.67)">
  //             <ellipse
  //               className="ant-empty-img-5"
  //               cx="67.797"
  //               cy="106.89"
  //               rx="67.797"
  //               ry="12.668"
  //             />
  //             <path
  //               className="ant-empty-img-1"
  //               d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
  //             />
  //             <path
  //               className="ant-empty-img-2"
  //               d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
  //             />
  //             <path
  //               className="ant-empty-img-3"
  //               d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
  //             />
  //           </g>
  //           <path
  //             className="ant-empty-img-3"
  //             d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
  //           />
  //           <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
  //             <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
  //             <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
  //           </g>
  //         </g>
  //       </svg>
  //       <Box sx={{ mt: 1 }}>
  //         No candidate has marked availability respective this Job requirements
  //       </Box>
  //     </StyledGridOverlay>  
  //   );
  // }
  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        showDialog={false}
        title1="Permanent Application"
        showisDeleted={false}
        handleDeleteApi={handleDeleteApi}
      />

      {softwares?.length > 0 ? (
        <>
          <MDBox sx={{ marginTop: "2rem" }}>
            <DataGrid
              density="compact"
              getRowId={(row) => row._id}
              // slots={{
              //   noRowsOverlay: CustomNoRowsOverlay,
              // }}
              loading={loading ? true : false}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: `PermanentApplication-${formatDate()}`,
                  },
                },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    _id: true,
                    ID: true,
                    created_at: true,
                    createdBy: false,
                    updated_at: false,
                    updatedBy: false,
                  },
                },
              }}
              columns={columns}
              rows={[...softwares]}
          //     pageSize={pageSize}
          // rowCount={totalCount}
          // paginationMode="server"
          // pagination
          // page={currentPage}
          // onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          // onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          // rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              pageSize={100}
              rowsPerPageOptions={[100]}
              
              //isRowSelectable
              sx={{
                backgroundColor: "#ffff",
                color: "grey",
                height: "35rem",
                margin: "1rem",
                borderRadius: "0.5rem",
                "& .MuiDataGrid-cell": {
                  padding: "15px",
                  fontSize: "12px",
                },
                borderColor: "light-grey",
                "& .MuiDataGrid-cell:hover": {
                  color: "info",
                },
              }}
            />
          </MDBox>
        </>
      ) : (
        <>
          {" "}
          <MDBox
            sx={{
              marginTop: "2rem",
              paddingBottom: "18rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <CustomNoRowsOverlay />{" "} */}
          </MDBox>{" "}
        </>
      )}
    </DashboardLayout>
  );
}

export default PermanentApplication;
