import React, { useState, useEffect } from 'react';
import { TextField, Menu, MenuItem, ClickAwayListener, Paper, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SearchableDropdown = ({ options, selectedValue, onChange, placeholder }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [displayValue, setDisplayValue] = useState(selectedValue);

  const open = Boolean(anchorEl);
  const theme = useTheme();

  console.log("searchTerm", searchTerm)
  useEffect(() => {
    setFilteredOptions(
      options?.filter((option) =>
        option?.value?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  useEffect(() => {
    // Set display value based on selectedValue
    const selectedOption = options.find(option => option.key === selectedValue);
    setDisplayValue(selectedOption ? selectedOption.value : placeholder);
  }, [selectedValue, options, placeholder]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key, value) => {
    onChange(key);
    setDisplayValue(value); // Update display value
    handleClose();
  };

  return (
    <div>
      <TextField
        fullWidth
        readOnly
        value={displayValue}
        onClick={handleClick}
        variant="outlined"
        InputProps={{
          endAdornment: <span>▼</span>, // dropdown arrow indicator
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 260,
            width: '36%',
            marginTop: theme.spacing(-1),
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <TextField
              fullWidth
              placeholder="Search..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{  marginRight: theme.spacing(2), }}
            />
            <Paper>
              {filteredOptions.map((option) => (
                <MenuItem
                  key={option.key}
                  onClick={() => handleSelect(option.key, option.value)}
                >
                  <ListItemText primary={option.value} />
                </MenuItem>
              ))}
            </Paper>
          </div>
        </ClickAwayListener>
      </Menu>
    </div>
  );
};

export default SearchableDropdown;
